

/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v20/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

}

@font-face {
  font-family: "Exo 2";
  src: url("Shabnam-FD-WOL.eot");
  src: url("Shabnam-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-FD-WOL.woff2") format("woff2"),
    url("Shabnam-FD-WOL.woff") format("woff"),
    url("Shabnam-FD-WOL.ttf") format("truetype");
  font-weight: normal;
  unicode-range: U+0600-06FF;
}

@font-face {
  font-family: "Exo 2";
  src: url("Shabnam-Bold-FD-WOL.eot");
  src: url("Shabnam-Bold-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Bold-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Bold-FD-WOL.woff") format("woff"),
    url("Shabnam-Bold-FD-WOL.ttf") format("truetype");
  font-weight: bold;
  unicode-range: U+0600-06FF;
}

@font-face {
  font-family: "Exo 2";
  src: url("Shabnam-Thin-FD-WOL.eot");
  src: url("Shabnam-Thin-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Thin-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Thin-FD-WOL.woff") format("woff"),
    url("Shabnam-Thin-FD-WOL.ttf") format("truetype");
  font-weight: 100;
  unicode-range: U+0600-06FF;
}

@font-face {
  font-family: "Exo 2";
  src: url("Shabnam-Light-FD-WOL.eot");
  src: url("Shabnam-Light-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Light-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Light-FD-WOL.woff") format("woff"),
    url("Shabnam-Light-FD-WOL.ttf") format("truetype");
  font-weight: 300;
  unicode-range: U+0600-06FF;
}

@font-face {
  font-family: "Exo 2";
  src: url("Shabnam-Medium-FD-WOL.eot");
  src: url("Shabnam-Medium-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Medium-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Medium-FD-WOL.woff") format("woff"),
    url("Shabnam-Medium-FD-WOL.ttf") format("truetype");
  font-weight: 500;
  unicode-range: U+0600-06FF;
}


/* shabnam */
@font-face {
  font-family: "Shabnam";
  src: url("Shabnam-FD-WOL.eot");
  src: url("Shabnam-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-FD-WOL.woff2") format("woff2"),
    url("Shabnam-FD-WOL.woff") format("woff"),
    url("Shabnam-FD-WOL.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Shabnam";
  src: url("Shabnam-Bold-FD-WOL.eot");
  src: url("Shabnam-Bold-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Bold-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Bold-FD-WOL.woff") format("woff"),
    url("Shabnam-Bold-FD-WOL.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Shabnam";
  src: url("Shabnam-Thin-FD-WOL.eot");
  src: url("Shabnam-Thin-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Thin-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Thin-FD-WOL.woff") format("woff"),
    url("Shabnam-Thin-FD-WOL.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Shabnam";
  src: url("Shabnam-Light-FD-WOL.eot");
  src: url("Shabnam-Light-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Light-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Light-FD-WOL.woff") format("woff"),
    url("Shabnam-Light-FD-WOL.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Shabnam";
  src: url("Shabnam-Medium-FD-WOL.eot");
  src: url("Shabnam-Medium-FD-WOL.eot?#iefix") format("embedded-opentype"),
    url("Shabnam-Medium-FD-WOL.woff2") format("woff2"),
    url("Shabnam-Medium-FD-WOL.woff") format("woff"),
    url("Shabnam-Medium-FD-WOL.ttf") format("truetype");
  font-weight: 500;
}