:root {
  --toastify-color-info: #35b8e0;
  --toastify-color-success: #10c469;
  --toastify-color-warning: #f9c851;
  --toastify-color-error: #ff5b5b;
  /* --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c; */
  
}
body.my-dark {
  --bg-dark: #202021;
  --bg-dark-lighter: #282828;
  --dark-gray: #313030;
  --dark-gray-1: #3b3b3b;
  --dark-gray-2: #4e4e4e;
  --a-purple: #ff2e2e;
  --a-purple--hover: #f72020;
  --a-purple--light: #ff4b4b;
  --text-color: #e3eaef;
  /* --bg: */
  --error: #ff2e2e;
}
.Toastify__toast-body{
  font-family: "Exo 2", 'Shabnam', sans-serif!important;
}
.font-shabnam{
  /* font-family:"Exo 2"; */
}
a:hover{
  text-decoration: none;
}

.v-none{
  visibility: hidden;
}
.nav-user {
  padding: calc(30px / 2) 20px calc(30px / 2) 57px !important;
}
.nav-user .account-position{
  margin-top:initial;
  font-size: 0.75em;
}
.my-dark .card {
  background-color: var(--bg-dark);
  -webkit-box-shadow: none;
  box-shadow: none;
}
body[data-layout="detached"].my-dark .leftside-menu {
  background: var(--bg-dark) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.my-dark .chart-content-bg {
  background-color: var(--dark-gray);
}

.my-dark .page-title-box .page-title {
  color: var(--text-color);
}

.my-dark .border-primary {
  border-color: var(--a-purple) !important;
}
.my-dark .btn-primary {
  color: #000;
  background-color: var(--a-purple);
  border-color: var(--a-purple);
}
.my-dark .dropdown-menu {
  color: #aab8c5;
  background-color: var(--dark-gray);
  border: 1px solid var(--dark-gray);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.my-dark .dropdown-item:focus,
.my-dark .dropdown-item:hover {
  background-color: var(--bg-dark);
}

.my-dark .form-control-light {
  background-color: var(--dark-gray) !important;
  border-color: var(--dark-gray) !important;
}

.my-dark .form-control::placeholder {
  color: var(--bs-body-color);
}
.my-dark .form-control {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
  color: var(--bs-body-color);
}
.my-dark .form-control:focus {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.19), 0 0px 6px rgba(0, 0, 0, 0.23) !important;
}
.my-dark input:disabled {
  color: #666;
}

.my-dark .select2-container .select2-selection--single {
  background-color: var(--dark-gray);
  border-color: var(--dark-gray);
}
.select2-container .select2-selection--multiple {
  outline: 0;
  border: 0;
  background-color: var(--dark-gray);
  /* border-color: var(--dark-gray); */
  color: var(--text-color);
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: var(--dark-gray-1);
}
.select2-container--default
  .select2-results__option[aria-selected="true"]:hover {
  background-color: var(--error);
}
.select2-container .select2-selection--multiple {
  border: 1px solid var(--dark-gray) !important;
}
.select2-dropdown {
  border: 0px solid var(--dark-gray);
  background-color: var(--dark-gray);
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: var(--a-purple);
  color: #000;
}
.select2-container
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #000;
}
.select2-container
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #222;
}

.my-dark
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  outline: 0;
  border: 1px solid var(--dark-gray);
  background-color: var(--dark-gray);
  color: var(--text-color);
}

.my-dark .form-select {
  display: block;
  width: 100%;
  padding: 0.45rem 2.5rem 0.45rem 0.9rem;
  -moz-padding-start: calc(0.9rem - 3px);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e3eaef;
  background-color: var(--dark-gray);
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 16px 12px;
  border: 1px solid var(--dark-gray);
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.my-dark .form-check-input:checked {
  background-color: var(--btn-bg);
  border-color: var(--btn-bg);
}

.my-dark .form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}

.my-dark .form-check-input:not(:checked)[type="checkbox"] {
  /* background-color: var(--bg); */
  /* border-color: var(--bg); */
}

.my-dark .form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--btn-bg);
  border-color: var(--btn-bg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.my-dark .text-muted {
  color: #999 !important;
}

.my-dark input[type="submit"],
.my-dark .form-control[type="button"] {
  background-color: var(--a-purple);
  color: #000;
}

.my-dark input[type="submit"]:hover,
.my-dark input[type="submit"]:focus {
  background-color: var(--a-purple);
}

.my-dark input[type="submit"].delete-btn,
.my-dark .form-control[type="button"].delete-btn {
  background-color: var(--bs-red);
  color: #000;
}

.my-dark input[type="submit"].delete-btn:hover,
.my-dark input[type="submit"].delete-btn:focus {
  background-color: var(--bs-red);
  color: #000;

}
.form-control.invalid{
  border-color:var(--error)!important;
  position: relative;
}

/* select */
.my-dark .bootstrap-select {
  border-radius: 0.25rem;
}

.my-dark .bootstrap-select,
.my-dark .bootstrap-select .btn-light,
.my-dark .bootstrap-select .filter-option {
  box-shadow: none;
  border: none;
  outline: none;
  border-radius: 0.25rem;
}

.my-dark .bootstrap-select .btn-light {
  outline: none !important;
}

.my-dark .bootstrap-select .btn-light,
.my-dark .btn-check:active + .btn-light,
.my-dark .btn-check:checked + .btn-light,
.my-dark .btn-light.active,
.my-dark .btn-light:active,
.my-dark .show > .btn-light.dropdown-toggle {
  background: var(--dark-gray);
  outline: none;
}

.my-dark .bootstrap-select li.no-results {
  background-color: inherit;
}

.my-dark .bootstrap-select .bs-ok-default:after {
  color: var(--a-purple);
}

.my-dark .dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--bg);
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  cursor: auto;
}

::-webkit-scrollbar-track {
  background-color: #252525;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #61616188;
}





.list-group-item{
  color: var(--mainC);
  background-color: var(--bg-dark);
  border: 1px solid #4d5662;
}


/* progress bar */
.progress-bar {
  color: #fff;
  background-color: var(--a-purple);
  transition: width 0.6s ease;
}
.progress {
  background-color: var(--dark-gray-1);
}
.project-overflow{
  display:block;
  width:90%;
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* end progress bar */


.cursor-pointer{
  cursor: pointer;
}
/* /////////////////////////////// */
/* body {
  --mainC: #181919;
  --bg: #eee;
  --bg2: #e5e5e5;
  --bg3: #fff;

  --inputs-bg: var(--bg2);
  --btn-bg: #181919;
  --btn-bg--hover: #242525;
  --btn-color: var(--bg2);
  --btn-color--hover: var(--bg2);
  --error: #ff2e2e;
} */
body.my-dark {
  font-family: "Exo 2", 'Shabnam', sans-serif;
  /* font-family: "Exo 2", 'Exo 2', Courier, monospace; */
  --invert-theme-text-color:#181919;
  --mainC: #aaa;
  --bg: #181919;
  --bg2: #202021;
  --bg3: #313030;

  /* inputs */
  --inputs-bg: var(--bg2);
  --btn-bg: var(--a-purple);
  --btn-bg--hover: var(--a-purple);
  --btn-color: #000;
}
body.all-purple {
  /* --mainC:#FFCC33; */
}

/* TOGGLER */
.dl-toggler {
  margin-top: 22.5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.toggle {
  --size: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) / 3) calc(var(--size) * -0.2) 0;
  border-radius: 999px;
  color: #e6e6ff;
}
.toggle:checked {
  --ray-size: calc(var(--size) * -0.4);
  --offset-orthogonal: calc(var(--size) * 0.65);
  --offset-diagonal: calc(var(--size) * 0.45);
  transform: scale(0.75);
  /* color: #000; */
  color: #fa0;
  box-shadow: inset 0 0 0 var(--size),
    calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size),
    0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0
      var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}



.loading-table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loading-table:after {
  content: "";
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 2px solid;
  border-color: var(--a-purple) transparent;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.dropdown-item{
  line-height: 2;
}

/*  */
div.very-big-on-hover-desktop{
  position: fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  width: 100vw !important;
  min-height: 100vh;
  background-color: var(--bg-dark);
}
.very-big-on-hover-desktop img{
  position: fixed;
  left:50%;
  transform: translateX(-50%);
  width: 70vw !important;
  height:auto;
}
a.rs-dropdown-item{
  color: var(--mainC)!important;
}

a.rs-dropdown-item.rs-dropdown-item-active{
  color: var(--a-purple)!important;
}

.player-overlay{
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ;
  z-index:1; 
}

.player-overlay.video-playing{
  width:auto;
  height:auto;
  bottom:0;
  left:0;
  transform: translate(0%, 0%) ;
}
.player-overlay:not(.video-playing){
  background-color: #0005;
}
.player-overlay .player-play,
.player-overlay .player-pause{
  cursor: pointer;
}
.player-overlay.video-playing .player-play,
.player-overlay:not(.video-playing) .player-pause{
  display: none;
}
.player-overlay:not(.video-playing) .player-play,
.player-overlay.video-playing .player-pause{
  display: inline;
}
.player-overlay.video-playing .player-pause{
  opacity: .5;
}
.player-overlay.video-playing{
  align-items: end;
  justify-content: start;
}