@charset "UTF-8";
/* @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@100;200;300;400;500;600;900&display=swap'); */
@import "../fonts/shabnam/base.css";
body.my-dark {
  --a-purple:#fb55f9;
  --bs-blue: #3d73dd;
  --bs-indigo: #727cf5;
  --bs-purple: #536de6;
  --bs-pink: #ff679b;
  --bs-red: #ff5b5b;
  --bs-orange: #fd7e14;
  --bs-yellow: #f9c851;
  --bs-green: #10c469;
  --bs-teal: #02a8b5;
  --bs-cyan: #35b8e0;
  --bs-white: #fff;
  --bs-gray: #ced4da;
  --bs-gray-dark: #e3eaef;
  --bs-gray-100: #404954;
  --bs-gray-200: #37404a;
  --bs-gray-300: #464f5b;
  --bs-gray-400: #8391a2;
  --bs-gray-500: #aab8c5;
  --bs-gray-600: #ced4da;
  --bs-gray-700: #dee2e6;
  --bs-gray-800: #e3eaef;
  --bs-gray-900: #e3eaef;
  --bs-primary: var(--bs-indigo);
  --bs-secondary: var(--bs-gray-600);
  --bs-success: var(--bs-green);
  --bs-info: var(--bs-cyan);
  --bs-warning: var(--bs-yellow);
  --bs-danger: var(--bs-red);
  --bs-light: var(--bs-gray-300);
  --bs-dark: var(--bs-gray-900);
  --bs-primary-rgb: 83, 109, 230;
  --bs-secondary-rgb: 206, 212, 218;
  --bs-success-rgb: 16, 196, 105;
  --bs-info-rgb: 53, 184, 224;
  --bs-warning-rgb: 249, 200, 81;
  --bs-danger-rgb: 255, 91, 91;
  --bs-light-rgb: 70, 79, 91;
  --bs-dark-rgb: 241, 241, 241;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 170, 184, 197;
  --bs-font-sans-serif: sans-serif;
  --bs-font-monospace: "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: sans-serif;
  --bs-body-font-size: 0.9rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: var(--bs-gray-500);
  --bs-body-bg: #181919;
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.1;
}
.h1,
h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.25rem;
  }
}
.h2,
h2 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 1.875rem;
  }
}
.h3,
h3 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.5rem;
  }
}
.h4,
h4 {
  font-size: 1.125rem;
}
.h5,
h5 {
  font-size: 0.9375rem;
}
.h6,
h6 {
  font-size: 0.75rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-bs-original-title],
abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
.small,
small {
  font-size: 0.75rem;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: var(--a-purple);
  text-decoration: none;
}
a:hover {
  color: var(--a-purple);
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%;
  color: var(--bs-gray-500);
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 87.5%;
  color: var(--bs-cyan);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: var(--bs-gray-900);
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
caption {
  padding-top: 0.95rem;
  padding-bottom: 0.95rem;
  color: var(--bs-gray-400);
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::file-selector-button {
  font: inherit;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
.lead {
  font-size: 1.125rem;
  font-weight: 300;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 6px;
}
.initialism {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: var(--bs-gray-600);
}
.blockquote-footer::before {
  content: "\2014\00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-gray-300);
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.75rem;
  color: var(--bs-gray-600);
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 12px);
  padding-left: var(--bs-gutter-x, 12px);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.col {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}
.row-cols-auto > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}
.row-cols-4 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}
.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333%;
}
.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}
.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}
.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66667%;
}
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333%;
}
.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66667%;
}
.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333%;
}
.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66667%;
}
.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333%;
}
.offset-2 {
  margin-left: 16.66667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333%;
}
.offset-5 {
  margin-left: 41.66667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333%;
}
.offset-8 {
  margin-left: 66.66667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333%;
}
.offset-11 {
  margin-left: 91.66667%;
}
.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}
.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}
.g-1,
.gx-1 {
  --bs-gutter-x: 0.375rem;
}
.g-1,
.gy-1 {
  --bs-gutter-y: 0.375rem;
}
.g-2,
.gx-2 {
  --bs-gutter-x: 0.75rem;
}
.g-2,
.gy-2 {
  --bs-gutter-y: 0.75rem;
}
.g-3,
.gx-3 {
  --bs-gutter-x: 1.5rem;
}
.g-3,
.gy-3 {
  --bs-gutter-y: 1.5rem;
}
.g-4,
.gx-4 {
  --bs-gutter-x: 2.25rem;
}
.g-4,
.gy-4 {
  --bs-gutter-y: 2.25rem;
}
.g-5,
.gx-5 {
  --bs-gutter-x: 4.5rem;
}
.g-5,
.gy-5 {
  --bs-gutter-y: 4.5rem;
}
@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 4.5rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 4.5rem;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.45rem + 1px);
  padding-bottom: calc(0.45rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: calc(1.2501rem + 0.0012vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.251rem;
  }
}
.col-form-label-sm {
  padding-top: calc(0.28rem + 1px);
  padding-bottom: calc(0.28rem + 1px);
  font-size: 0.875rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--bs-gray-400);
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-gray-800);
  background-color: var(--bs-gray-100);
  background-clip: padding-box;
  border: 1px solid #4a525d;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-gray-800);
  background-color: var(--bs-gray-300);
  border-color: #555f6b;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-webkit-input-placeholder {
  color: var(--bs-gray-400);
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: var(--bs-gray-400);
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: var(--bs-gray-400);
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: var(--bs-gray-400);
  opacity: 1;
}
.form-control::placeholder {
  color: var(--bs-gray-400);
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: var(--bs-gray-200);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.45rem 0.9rem;
  margin: -0.45rem -0.9rem;
  -webkit-margin-end: 0.9rem;
  margin-inline-end: 0.9rem;
  color: var(--bs-gray-800);
  background-color: #48515d;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #444d58;
}
.form-control::-webkit-file-upload-button {
  padding: 0.45rem 0.9rem;
  margin: -0.45rem -0.9rem;
  -webkit-margin-end: 0.9rem;
  margin-inline-end: 0.9rem;
  color: var(--bs-gray-800);
  background-color: #48515d;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #444d58;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.45rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-gray-500);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.5em + 0.56rem + 2px);
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.28rem 0.8rem;
  margin: -0.28rem -0.8rem;
  -webkit-margin-end: 0.8rem;
  margin-inline-end: 0.8rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.28rem 0.8rem;
  margin: -0.28rem -0.8rem;
  -webkit-margin-end: 0.8rem;
  margin-inline-end: 0.8rem;
}
.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.251rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
textarea.form-control {
  min-height: calc(1.5em + 0.9rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.56rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}
.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.45rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.45rem 2.5rem 0.45rem 0.9rem;
  -moz-padding-start: calc(0.9rem - 3px);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-gray-800);
  background-color: var(--bs-gray-100);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e3eaef' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.9rem center;
  background-size: 16px 12px;
  border: 1px solid #4a525d;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    -webkit-transition: none;
    transition: none;
  }
}
.form-select:focus {
  border-color: #555f6b;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.9rem;
  background-image: none;
}
.form-select:disabled {
  color: var(--bs-gray-600);
  background-color: var(--bs-gray-200);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-gray-800);
}
.form-select-sm {
  padding-top: 0.28rem;
  padding-bottom: 0.28rem;
  padding-left: 0.8rem;
  font-size: 0.875rem;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.251rem;
  }
}
.form-check {
  display: block;
  min-height: 1.35rem;
  padding-left: 1.612em;
  margin-bottom: 0;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.612em;
}
.form-check-input {
  width: 1.112em;
  height: 1.112em;
  margin-top: 0.194em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #4a525d;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  -webkit-transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #555f6b;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23515a65'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  -webkit-transition: background-position 0.15s ease-in-out;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23555f6b'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}
.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px var(--bs-body-bg), none;
  box-shadow: 0 0 0 1px var(--bs-body-bg), none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px var(--bs-body-bg), none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: var(--bs-indigo);
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #cbd3f8;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-gray-300);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: var(--bs-indigo);
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #cbd3f8;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-gray-300);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-gray-500);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-gray-500);
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.9rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transition: opacity 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    -webkit-transition: none;
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.9rem;
}
.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-gray-800);
  text-align: center;
  white-space: nowrap;
  background-color: #48515d;
  border: 1px solid #4a525d;
  border-radius: 0.25rem;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.3rem;
}
@media (min-width: 1200px) {
  .input-group-lg > .btn,
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text {
    font-size: 1.251rem;
  }
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.4rem;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--bs-green);
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.8rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: rgba(16, 196, 105, 0.9);
  border-radius: 0.2rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:not(.no-validate):valid {
  border-color: var(--bs-green);
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310c469' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:not(.no-validate):valid:focus {
  border-color: var(--bs-green);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right
    calc(0.375em + 0.225rem);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: var(--bs-green);
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e3eaef' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310c469' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.9rem center, center right 2.5rem;
  background-size: 16px 12px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: var(--bs-green);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.25);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: var(--bs-green);
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: var(--bs-green);
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: var(--bs-green);
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: var(--bs-red);
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.4rem 0.8rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: rgba(255, 91, 91, 0.9);
  border-radius: 0.2rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-red);
  padding-right: calc(1.5em + 0.9rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5b5b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5b5b' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.225rem) center;
  background-size: calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: var(--bs-red);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.9rem);
  background-position: top calc(0.375em + 0.225rem) right
    calc(0.375em + 0.225rem);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: var(--bs-red);
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23e3eaef' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff5b5b'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff5b5b' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.9rem center, center right 2.5rem;
  background-size: 16px 12px, calc(0.75em + 0.45rem) calc(0.75em + 0.45rem);
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: var(--bs-red);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.25);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: var(--bs-red);
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: var(--bs-red);
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: var(--bs-red);
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-gray-500);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.45rem 0.9rem;
  font-size: 0.9rem;
  border-radius: 0.15rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-gray-500);
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.25);
}
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}
.btn-primary {
  color: #fff;
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.btn-primary:hover {
  color: #fff;
  background-color: #475dc4;
  border-color: #4257b8;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #475dc4;
  border-color: #4257b8;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(109, 131, 234, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(109, 131, 234, 0.5);
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4257b8;
  border-color: #3e52ad;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(109, 131, 234, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(109, 131, 234, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.btn-secondary {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-600);
  border-color: var(--bs-gray-600);
}
.btn-secondary:hover {
  color: var(--bs-gray-200);
  background-color: #d5dae0;
  border-color: #d3d8de;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: var(--bs-gray-200);
  background-color: #d5dae0;
  border-color: #d3d8de;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(183, 190, 196, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(183, 190, 196, 0.5);
}
.btn-check:active + .btn-secondary,
.btn-check:checked + .btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show > .btn-secondary.dropdown-toggle {
  color: var(--bs-gray-200);
  background-color: #d8dde1;
  border-color: #d3d8de;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(183, 190, 196, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(183, 190, 196, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-600);
  border-color: var(--bs-gray-600);
}
.btn-success {
  color: #fff;
  background-color: var(--bs-green);
  border-color: var(--bs-green);
}
.btn-success:hover {
  color: #fff;
  background-color: #0ea759;
  border-color: #0d9d54;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #0ea759;
  border-color: #0d9d54;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 205, 128, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 205, 128, 0.5);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0d9d54;
  border-color: #0c934f;
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 205, 128, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(52, 205, 128, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: var(--bs-green);
  border-color: var(--bs-green);
}
.btn-info {
  color: #fff;
  background-color: var(--bs-cyan);
  border-color: var(--bs-cyan);
}
.btn-info:hover {
  color: #fff;
  background-color: #2d9cbe;
  border-color: #2a93b3;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #fff;
  background-color: #2d9cbe;
  border-color: #2a93b3;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 195, 229, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(83, 195, 229, 0.5);
}
.btn-check:active + .btn-info,
.btn-check:checked + .btn-info,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2a93b3;
  border-color: #288aa8;
}
.btn-check:active + .btn-info:focus,
.btn-check:checked + .btn-info:focus,
.btn-info.active:focus,
.btn-info:active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 195, 229, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(83, 195, 229, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: var(--bs-cyan);
  border-color: var(--bs-cyan);
}
.btn-warning {
  color: var(--bs-gray-200);
  background-color: var(--bs-yellow);
  border-color: var(--bs-yellow);
}
.btn-warning:hover {
  color: var(--bs-gray-200);
  background-color: #fad06b;
  border-color: #face62;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: var(--bs-gray-200);
  background-color: #fad06b;
  border-color: #face62;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 180, 80, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 180, 80, 0.5);
}
.btn-check:active + .btn-warning,
.btn-check:checked + .btn-warning,
.btn-warning.active,
.btn-warning:active,
.show > .btn-warning.dropdown-toggle {
  color: var(--bs-gray-200);
  background-color: #fad374;
  border-color: #face62;
}
.btn-check:active + .btn-warning:focus,
.btn-check:checked + .btn-warning:focus,
.btn-warning.active:focus,
.btn-warning:active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 180, 80, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(220, 180, 80, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: var(--bs-gray-200);
  background-color: var(--bs-yellow);
  border-color: var(--bs-yellow);
}
.btn-danger {
  color: #fff;
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}
.btn-danger:hover {
  color: #fff;
  background-color: #d94d4d;
  border-color: #cc4949;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #d94d4d;
  border-color: #cc4949;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 116, 116, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 116, 116, 0.5);
}
.btn-check:active + .btn-danger,
.btn-check:checked + .btn-danger,
.btn-danger.active,
.btn-danger:active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #cc4949;
  border-color: #bf4444;
}
.btn-check:active + .btn-danger:focus,
.btn-check:checked + .btn-danger:focus,
.btn-danger.active:focus,
.btn-danger:active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 116, 116, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 116, 116, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}
.btn-light {
  color: #fff;
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}
.btn-light:hover {
  color: #fff;
  background-color: #3c434d;
  border-color: #383f49;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #fff;
  background-color: #3c434d;
  border-color: #383f49;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(98, 105, 116, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(98, 105, 116, 0.5);
}
.btn-check:active + .btn-light,
.btn-check:checked + .btn-light,
.btn-light.active,
.btn-light:active,
.show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #383f49;
  border-color: #353b44;
}
.btn-check:active + .btn-light:focus,
.btn-check:checked + .btn-light:focus,
.btn-light.active:focus,
.btn-light:active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(98, 105, 116, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(98, 105, 116, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #fff;
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}
.btn-dark {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-900);
  border-color: var(--bs-gray-900);
}
.btn-dark:hover {
  color: var(--bs-gray-200);
  background-color: #f3f3f3;
  border-color: #f2f2f2;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: var(--bs-gray-200);
  background-color: #f3f3f3;
  border-color: #f2f2f2;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(213, 214, 216, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 216, 0.5);
}
.btn-check:active + .btn-dark,
.btn-check:checked + .btn-dark,
.btn-dark.active,
.btn-dark:active,
.show > .btn-dark.dropdown-toggle {
  color: var(--bs-gray-200);
  background-color: #f4f4f4;
  border-color: #f2f2f2;
}
.btn-check:active + .btn-dark:focus,
.btn-check:checked + .btn-dark:focus,
.btn-dark.active:focus,
.btn-dark:active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(213, 214, 216, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(213, 214, 216, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-900);
  border-color: var(--bs-gray-900);
}
.btn-outline-primary {
  color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--bs-indigo);
  background-color: transparent;
}
.btn-outline-secondary {
  color: var(--bs-gray-600);
  border-color: var(--bs-gray-600);
}
.btn-outline-secondary:hover {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-600);
  border-color: var(--bs-gray-600);
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-600);
  border-color: var(--bs-gray-600);
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(206, 212, 218, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: var(--bs-gray-600);
  background-color: transparent;
}
.btn-outline-success {
  color: var(--bs-green);
  border-color: var(--bs-green);
}
.btn-outline-success:hover {
  color: #fff;
  background-color: var(--bs-green);
  border-color: var(--bs-green);
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.5);
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: var(--bs-green);
  border-color: var(--bs-green);
}
.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(16, 196, 105, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: var(--bs-green);
  background-color: transparent;
}
.btn-outline-info {
  color: var(--bs-cyan);
  border-color: var(--bs-cyan);
}
.btn-outline-info:hover {
  color: #fff;
  background-color: var(--bs-cyan);
  border-color: var(--bs-cyan);
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(53, 184, 224, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(53, 184, 224, 0.5);
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #fff;
  background-color: var(--bs-cyan);
  border-color: var(--bs-cyan);
}
.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(53, 184, 224, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(53, 184, 224, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: var(--bs-cyan);
  background-color: transparent;
}
.btn-outline-warning {
  color: var(--bs-yellow);
  border-color: var(--bs-yellow);
}
.btn-outline-warning:hover {
  color: var(--bs-gray-200);
  background-color: var(--bs-yellow);
  border-color: var(--bs-yellow);
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 200, 81, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(249, 200, 81, 0.5);
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: var(--bs-gray-200);
  background-color: var(--bs-yellow);
  border-color: var(--bs-yellow);
}
.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(249, 200, 81, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(249, 200, 81, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: var(--bs-yellow);
  background-color: transparent;
}
.btn-outline-danger {
  color: var(--bs-red);
  border-color: var(--bs-red);
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.5);
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}
.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 91, 91, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: var(--bs-red);
  background-color: transparent;
}
.btn-outline-light {
  color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}
.btn-outline-light:hover {
  color: #fff;
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 79, 91, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 79, 91, 0.5);
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #fff;
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}
.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(70, 79, 91, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(70, 79, 91, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: var(--bs-gray-300);
  background-color: transparent;
}
.btn-outline-dark {
  color: var(--bs-gray-900);
  border-color: var(--bs-gray-900);
}
.btn-outline-dark:hover {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-900);
  border-color: var(--bs-gray-900);
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: var(--bs-gray-200);
  background-color: var(--bs-gray-900);
  border-color: var(--bs-gray-900);
}
.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(241, 241, 241, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: var(--bs-gray-900);
  background-color: transparent;
}
.btn-link {
  font-weight: 400;
  color: var(--bs-indigo);
  text-decoration: none;
}
.btn-link:hover {
  color: #475dc4;
}
.btn-link.disabled,
.btn-link:disabled {
  color: var(--bs-gray-500);
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: calc(1.2501rem + 0.0012vw);
  border-radius: 0.15rem;
}
@media (min-width: 1200px) {
  .btn-group-lg > .btn,
  .btn-lg {
    font-size: 1.251rem;
  }
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.15rem;
}
.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  -webkit-transition: width 0.35s ease;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    -webkit-transition: none;
    transition: none;
  }
}
.dropdown,
.dropend,
.dropstart,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0;
  font-size: 0.9rem;
  color: var(--bs-gray-500);
  text-align: left;
  list-style: none;
  background-color: var(--bs-gray-300);
  background-clip: padding-box;
  border: 1px solid #444d58;
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0;
  border-right: 0.25em solid transparent;
  border-bottom: 0.25em solid;
  border-left: 0.25em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0;
  border-bottom: 0.25em solid transparent;
  border-left: 0.25em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.2125em;
  vertical-align: 0.2125em;
  content: "";
  border-top: 0.25em solid transparent;
  border-right: 0.25em solid;
  border-bottom: 0.25em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.45rem 0;
  overflow: hidden;
  border-top: 1px solid #4d5662;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: var(--bs-gray-500);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #d9d9d9;
  background-color: #4d5764;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #7a8089;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-gray-600);
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: inherit;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.375rem 1.5rem;
  color: var(--bs-gray-500);
}
.dropdown-menu-dark {
  color: var(--bs-gray-300);
  background-color: var(--bs-gray-800);
  border-color: #444d58;
}
.dropdown-menu-dark .dropdown-item {
  color: var(--bs-gray-300);
}
.dropdown-menu-dark .dropdown-item:focus,
.dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #7a8089;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: var(--bs-gray-500);
}
.dropdown-menu-dark .dropdown-divider {
  border-color: #4d5662;
}
.dropdown-menu-dark .dropdown-item-text {
  color: var(--bs-gray-300);
}
.dropdown-menu-dark .dropdown-header {
  color: var(--bs-gray-500);
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:hover,
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.675rem;
  padding-left: 0.675rem;
}
.dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: var(--bs-indigo);
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    -webkit-transition: none;
    transition: none;
  }
}
.nav-link:focus,
.nav-link:hover {
  color: #475dc4;
}
.nav-link.disabled {
  color: var(--bs-gray-600);
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid var(--bs-gray-300);
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: var(--bs-gray-200) var(--bs-gray-200) var(--bs-gray-300);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: var(--bs-gray-600);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-gray-800);
  background-color: #4a525d;
  border-color: var(--bs-gray-300) var(--bs-gray-300) #4a525d;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--bs-indigo);
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--bs-gray-200);
  background-clip: border-box;
  border: 1px solid #4d5662;
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}
.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: var(--bs-gray-300);
  border-bottom: 1px solid #4d5662;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: var(--bs-gray-300);
  border-top: 1px solid #4d5662;
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-gray-200);
  border-bottom-color: var(--bs-gray-200);
}
.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-group > .card {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.9rem;
  color: var(--bs-gray-500);
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, border-radius 0.15s ease,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #4b62cf;
  background-color: #eef0fd;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234b62cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23aab8c5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #555f6b;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.25);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-body {
  padding: 1rem 1.25rem;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.page-link {
  position: relative;
  display: block;
  color: var(--bs-gray-400);
  background-color: #3f4851;
  border: 1px solid var(--bs-gray-300);
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    -webkit-transition: none;
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-gray-900);
  background-color: #474f58;
  border-color: var(--bs-gray-300);
}
.page-link:focus {
  z-index: 3;
  color: #475dc4;
  background-color: var(--bs-gray-200);
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(83, 109, 230, 0.25);
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.page-item.disabled .page-link {
  color: var(--bs-gray-400);
  pointer-events: none;
  background-color: #4a555f;
  border-color: #4a555f;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: calc(1.2501rem + 0.0012vw);
}
@media (min-width: 1200px) {
  .pagination-lg .page-link {
    font-size: 1.251rem;
  }
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3.75rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.9375rem 1.25rem;
}
.alert-primary {
  color: #2a3773;
  background-color: #dde2fa;
  border-color: #cbd3f8;
}
.alert-primary .alert-link {
  color: #222c5c;
}
.alert-secondary {
  color: #676a6d;
  background-color: #f5f6f8;
  border-color: #f0f2f4;
}
.alert-secondary .alert-link {
  color: #525557;
}
.alert-success {
  color: #086235;
  background-color: #cff3e1;
  border-color: #b7edd2;
}
.alert-success .alert-link {
  color: #064e2a;
}
.alert-info {
  color: #1b5c70;
  background-color: #d7f1f9;
  border-color: #c2eaf6;
}
.alert-info .alert-link {
  color: #164a5a;
}
.alert-warning {
  color: #7d6429;
  background-color: #fef4dc;
  border-color: #fdefcb;
}
.alert-warning .alert-link {
  color: #645021;
}
.alert-danger {
  color: #802e2e;
  background-color: #ffdede;
  border-color: #ffcece;
}
.alert-danger .alert-link {
  color: #662525;
}
.alert-light {
  color: #23282e;
  background-color: #dadcde;
  border-color: #c8cace;
}
.alert-light .alert-link {
  color: #1c2025;
}
.alert-dark {
  color: #797979;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}
.alert-dark .alert-link {
  color: #616161;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.675rem;
  background-color: var(--bs-gray-300);
  border-radius: 0.25rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-indigo);
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: var(--bs-gray-500);
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: var(--bs-gray-500);
  text-decoration: none;
  background-color: var(--bs-gray-100);
}
.list-group-item-action:active {
  color: var(--bs-gray-700);
  background-color: var(--bs-gray-100);
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.2rem;
  color: var(--bs-gray-900);
  background-color: var(--bs-gray-200);
  border: 1px solid #4d5662;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-gray-400);
  pointer-events: none;
  background-color: var(--bs-gray-100);
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #32418a;
  background-color: #cbd3f8;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #32418a;
  background-color: #b7bedf;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #32418a;
  border-color: #32418a;
}
.list-group-item-secondary {
  color: #7c7f83;
  background-color: #f0f2f4;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #7c7f83;
  background-color: #d8dadc;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7c7f83;
  border-color: #7c7f83;
}
.list-group-item-success {
  color: #0a763f;
  background-color: #b7edd2;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #0a763f;
  background-color: #a5d5bd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0a763f;
  border-color: #0a763f;
}
.list-group-item-info {
  color: #206e86;
  background-color: #c2eaf6;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #206e86;
  background-color: #afd3dd;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #206e86;
  border-color: #206e86;
}
.list-group-item-warning {
  color: #957831;
  background-color: #fdefcb;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #957831;
  background-color: #e4d7b7;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #957831;
  border-color: #957831;
}
.list-group-item-danger {
  color: #993737;
  background-color: #ffcece;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #993737;
  background-color: #e6b9b9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #993737;
  border-color: #993737;
}
.list-group-item-light {
  color: #2a2f37;
  background-color: #c8cace;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #2a2f37;
  background-color: #b4b6b9;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #2a2f37;
  border-color: #2a2f37;
}
.list-group-item-dark {
  color: #919191;
  background-color: #fbfbfb;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #919191;
  background-color: #e2e2e2;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #919191;
  border-color: #919191;
}
.btn-close {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-gray-800);
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e3eaef'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.65;
}
.btn-close:hover {
  color: var(--bs-gray-800);
  text-decoration: none;
  opacity: 0.8;
}
.btn-close:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
}
.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}
.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 12px;
}
.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: var(--bs-gray-300);
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}
.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: var(--bs-gray-300);
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-gray-500);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.7;
}
.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #515c69;
  border-top-left-radius: calc(0.2rem - 1px);
  border-top-right-radius: calc(0.2rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #515c69;
  border-bottom-right-radius: calc(0.2rem - 1px);
  border-bottom-left-radius: calc(0.2rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[data-popper-placement^="top"],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: var(--bs-gray-700);
}
.bs-tooltip-auto[data-popper-placement^="right"],
.bs-tooltip-end {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: var(--bs-gray-700);
}
.bs-tooltip-auto[data-popper-placement^="bottom"],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow,
.bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: var(--bs-gray-700);
}
.bs-tooltip-auto[data-popper-placement^="left"],
.bs-tooltip-start {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: var(--bs-gray-700);
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.4rem 0.8rem;
  color: var(--bs-gray-200);
  text-align: center;
  background-color: var(--bs-gray-700);
  border-radius: 0.2rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: var(--bs-gray-700);
  background-clip: padding-box;
  border: 1px solid var(--bs-gray-200);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::after,
.popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow,
.bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before,
.bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: var(--bs-gray-400);
}
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after,
.bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: var(--bs-gray-700);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow,
.bs-popover-end > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before,
.bs-popover-end > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: var(--bs-gray-400);
}
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after,
.bs-popover-end > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: var(--bs-gray-700);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow,
.bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before,
.bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: var(--bs-gray-400);
}
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after,
.bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: var(--bs-gray-700);
}
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #e5e8eb;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow,
.bs-popover-start > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before,
.bs-popover-start > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--bs-gray-400);
}
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after,
.bs-popover-start > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: var(--bs-gray-700);
}
.popover-header {
  padding: 0.7rem 0.8rem;
  margin-bottom: 0;
  font-size: 0.9rem;
  color: var(--bs-gray-200);
  background-color: #e5e8eb;
  border-bottom: 1px solid var(--bs-gray-200);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: var(--bs-gray-200);
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: var(--bs-gray-300);
  background-clip: padding-box;
  outline: 0;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    -webkit-transition: none;
    transition: none;
  }
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-gray-500);
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.7;
}
.offcanvas-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.offcanvas-body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}
.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid transparent;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid transparent;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid transparent;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid transparent;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.offcanvas.show {
  -webkit-transform: none;
  transform: none;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}
@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}
@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.link-primary {
  color: var(--bs-indigo);
}
.link-primary:focus,
.link-primary:hover {
  color: #475dc4;
}
.link-secondary {
  color: var(--bs-gray-600);
}
.link-secondary:focus,
.link-secondary:hover {
  color: #d5dae0;
}
.link-success {
  color: var(--bs-green);
}
.link-success:focus,
.link-success:hover {
  color: #0ea759;
}
.link-info {
  color: var(--bs-cyan);
}
.link-info:focus,
.link-info:hover {
  color: #2d9cbe;
}
.link-warning {
  color: var(--bs-yellow);
}
.link-warning:focus,
.link-warning:hover {
  color: #fad06b;
}
.link-danger {
  color: var(--bs-red);
}
.link-danger:focus,
.link-danger:hover {
  color: #d94d4d;
}
.link-light {
  color: var(--bs-gray-300);
}
.link-light:focus,
.link-light:hover {
  color: #3c434d;
}
.link-dark {
  color: var(--bs-gray-900);
}
.link-dark:focus,
.link-dark:hover {
  color: #f3f3f3;
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}
.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}
.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.vstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.vr {
  display: inline-block;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.float-start {
  float: left !important;
}
.float-end {
  float: right !important;
}
.float-none {
  float: none !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.opacity-25 {
  opacity: 0.25 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-75 {
  opacity: 0.75 !important;
}
.opacity-100 {
  opacity: 1 !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-visible {
  overflow: visible !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.d-none {
  display: none !important;
}
.shadow {
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5) !important;
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5) !important;
}
.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow-lg {
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12) !important;
}
.shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.top-0 {
  top: 0 !important;
}
.top-50 {
  top: 50% !important;
}
.top-100 {
  top: 100% !important;
}
.bottom-0 {
  bottom: 0 !important;
}
.bottom-50 {
  bottom: 50% !important;
}
.bottom-100 {
  bottom: 100% !important;
}
.start-0 {
  left: 0 !important;
}
.start-50 {
  left: 50% !important;
}
.start-100 {
  left: 100% !important;
}
.end-0 {
  right: 0 !important;
}
.end-50 {
  right: 50% !important;
}
.end-100 {
  right: 100% !important;
}
.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}
.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}
.border {
  border: 1px solid var(--bs-gray-300) !important;
}
.border-0 {
  border: 0 !important;
}
.border-top {
  border-top: 1px solid var(--bs-gray-300) !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-end {
  border-right: 1px solid var(--bs-gray-300) !important;
}
.border-end-0 {
  border-right: 0 !important;
}
.border-bottom {
  border-bottom: 1px solid var(--bs-gray-300) !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-start {
  border-left: 1px solid var(--bs-gray-300) !important;
}
.border-start-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: var(--bs-indigo) !important;
}
.border-secondary {
  border-color: var(--bs-gray-600) !important;
}
.border-success {
  border-color: var(--bs-green) !important;
}
.border-info {
  border-color: var(--bs-cyan) !important;
}
.border-warning {
  border-color: var(--bs-yellow) !important;
}
.border-danger {
  border-color: var(--bs-red) !important;
}
.border-light {
  border-color: var(--bs-gray-300) !important;
}
.border-dark {
  border-color: var(--bs-gray-900) !important;
}
.border-white {
  border-color: #fff !important;
}
.border-0 {
  border-width: 0 !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  border-width: 2px !important;
}
.border-3 {
  border-width: 3px !important;
}
.border-4 {
  border-width: 4px !important;
}
.border-5 {
  border-width: 5px !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.vw-100 {
  width: 100vw !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mh-100 {
  max-height: 100% !important;
}
.vh-100 {
  height: 100vh !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.flex-fill {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-grow-0 {
  -webkit-box-flex: 0 !important;
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.gap-0 {
  gap: 0 !important;
}
.gap-1 {
  gap: 0.375rem !important;
}
.gap-2 {
  gap: 0.75rem !important;
}
.gap-3 {
  gap: 1.5rem !important;
}
.gap-4 {
  gap: 2.25rem !important;
}
.gap-5 {
  gap: 4.5rem !important;
}
.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.justify-content-evenly {
  -webkit-box-pack: space-evenly !important;
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
}
.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
.order-first {
  -webkit-box-ordinal-group: 0 !important;
  -ms-flex-order: -1 !important;
  order: -1 !important;
}
.order-0 {
  -webkit-box-ordinal-group: 1 !important;
  -ms-flex-order: 0 !important;
  order: 0 !important;
}
.order-1 {
  -webkit-box-ordinal-group: 2 !important;
  -ms-flex-order: 1 !important;
  order: 1 !important;
}
.order-2 {
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
}
.order-3 {
  -webkit-box-ordinal-group: 4 !important;
  -ms-flex-order: 3 !important;
  order: 3 !important;
}
.order-4 {
  -webkit-box-ordinal-group: 5 !important;
  -ms-flex-order: 4 !important;
  order: 4 !important;
}
.order-5 {
  -webkit-box-ordinal-group: 6 !important;
  -ms-flex-order: 5 !important;
  order: 5 !important;
}
.order-last {
  -webkit-box-ordinal-group: 7 !important;
  -ms-flex-order: 6 !important;
  order: 6 !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.375rem !important;
}
.m-2 {
  margin: 0.75rem !important;
}
.m-3 {
  margin: 1.5rem !important;
}
.m-4 {
  margin: 2.25rem !important;
}
.m-5 {
  margin: 4.5rem !important;
}
.m-auto {
  margin: auto !important;
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}
.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}
.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.mx-4 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}
.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}
.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}
.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.375rem !important;
}
.mt-2 {
  margin-top: 0.75rem !important;
}
.mt-3 {
  margin-top: 1.5rem !important;
}
.mt-4 {
  margin-top: 2.25rem !important;
}
.mt-5 {
  margin-top: 4.5rem !important;
}
.mt-auto {
  margin-top: auto !important;
}
.me-0 {
  margin-right: 0 !important;
}
.me-1 {
  margin-right: 0.375rem !important;
}
.me-2 {
  margin-right: 0.75rem !important;
}
.me-3 {
  margin-right: 1.5rem !important;
}
.me-4 {
  margin-right: 2.25rem !important;
}
.me-5 {
  margin-right: 4.5rem !important;
}
.me-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 0.375rem !important;
}
.mb-2 {
  margin-bottom: 0.75rem !important;
}
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 2.25rem !important;
}
.mb-5 {
  margin-bottom: 4.5rem !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ms-0 {
  margin-left: 0 !important;
}
.ms-1 {
  margin-left: 0.375rem !important;
}
.ms-2 {
  margin-left: 0.75rem !important;
}
.ms-3 {
  margin-left: 1.5rem !important;
}
.ms-4 {
  margin-left: 2.25rem !important;
}
.ms-5 {
  margin-left: 4.5rem !important;
}
.ms-auto {
  margin-left: auto !important;
}
.m-n1 {
  margin: -0.375rem !important;
}
.m-n2 {
  margin: -0.75rem !important;
}
.m-n3 {
  margin: -1.5rem !important;
}
.m-n4 {
  margin: -2.25rem !important;
}
.m-n5 {
  margin: -4.5rem !important;
}
.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}
.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}
.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.mx-n4 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}
.mx-n5 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}
.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}
.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}
.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}
.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}
.mt-n1 {
  margin-top: -0.375rem !important;
}
.mt-n2 {
  margin-top: -0.75rem !important;
}
.mt-n3 {
  margin-top: -1.5rem !important;
}
.mt-n4 {
  margin-top: -2.25rem !important;
}
.mt-n5 {
  margin-top: -4.5rem !important;
}
.me-n1 {
  margin-right: -0.375rem !important;
}
.me-n2 {
  margin-right: -0.75rem !important;
}
.me-n3 {
  margin-right: -1.5rem !important;
}
.me-n4 {
  margin-right: -2.25rem !important;
}
.me-n5 {
  margin-right: -4.5rem !important;
}
.mb-n1 {
  margin-bottom: -0.375rem !important;
}
.mb-n2 {
  margin-bottom: -0.75rem !important;
}
.mb-n3 {
  margin-bottom: -1.5rem !important;
}
.mb-n4 {
  margin-bottom: -2.25rem !important;
}
.mb-n5 {
  margin-bottom: -4.5rem !important;
}
.ms-n1 {
  margin-left: -0.375rem !important;
}
.ms-n2 {
  margin-left: -0.75rem !important;
}
.ms-n3 {
  margin-left: -1.5rem !important;
}
.ms-n4 {
  margin-left: -2.25rem !important;
}
.ms-n5 {
  margin-left: -4.5rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.375rem !important;
}
.p-2 {
  padding: 0.75rem !important;
}
.p-3 {
  padding: 1.5rem !important;
}
.p-4 {
  padding: 2.25rem !important;
}
.p-5 {
  padding: 4.5rem !important;
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}
.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}
.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.px-4 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}
.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}
.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}
.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1 {
  padding-top: 0.375rem !important;
}
.pt-2 {
  padding-top: 0.75rem !important;
}
.pt-3 {
  padding-top: 1.5rem !important;
}
.pt-4 {
  padding-top: 2.25rem !important;
}
.pt-5 {
  padding-top: 4.5rem !important;
}
.pe-0 {
  padding-right: 0 !important;
}
.pe-1 {
  padding-right: 0.375rem !important;
}
.pe-2 {
  padding-right: 0.75rem !important;
}
.pe-3 {
  padding-right: 1.5rem !important;
}
.pe-4 {
  padding-right: 2.25rem !important;
}
.pe-5 {
  padding-right: 4.5rem !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 0.375rem !important;
}
.pb-2 {
  padding-bottom: 0.75rem !important;
}
.pb-3 {
  padding-bottom: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 2.25rem !important;
}
.pb-5 {
  padding-bottom: 4.5rem !important;
}
.ps-0 {
  padding-left: 0 !important;
}
.ps-1 {
  padding-left: 0.375rem !important;
}
.ps-2 {
  padding-left: 0.75rem !important;
}
.ps-3 {
  padding-left: 1.5rem !important;
}
.ps-4 {
  padding-left: 2.25rem !important;
}
.ps-5 {
  padding-left: 4.5rem !important;
}
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}
.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}
.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.fs-4 {
  font-size: 1.125rem !important;
}
.fs-5 {
  font-size: 0.9375rem !important;
}
.fs-6 {
  font-size: 0.75rem !important;
}
.fst-italic {
  font-style: italic !important;
}
.fst-normal {
  font-style: normal !important;
}
.fw-light {
  font-weight: 300 !important;
}
.fw-lighter {
  font-weight: lighter !important;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-bolder {
  font-weight: bolder !important;
}
.lh-1 {
  line-height: 1 !important;
}
.lh-sm {
  line-height: 1.25 !important;
}
.lh-base {
  line-height: 1.5 !important;
}
.lh-lg {
  line-height: 2 !important;
}
.text-start {
  text-align: left !important;
}
.text-end {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-decoration-underline {
  text-decoration: underline !important;
}
.text-decoration-line-through {
  text-decoration: line-through !important;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}
.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}
.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}
.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}
.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}
.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}
.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}
.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-rgb), var(--bs-text-opacity)) !important;
}
.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-gray-400) !important;
}
.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}
.text-opacity-25 {
  --bs-text-opacity: 0.25;
}
.text-opacity-50 {
  --bs-text-opacity: 0.5;
}
.text-opacity-75 {
  --bs-text-opacity: 0.75;
}
.text-opacity-100 {
  --bs-text-opacity: 1;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}
.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}
.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}
.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}
.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}
.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}
.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-rgb), var(--bs-bg-opacity)) !important;
}
.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}
.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}
.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}
.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}
.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}
.bg-opacity-100 {
  --bs-bg-opacity: 1;
}
.bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.pe-none {
  pointer-events: none !important;
}
.pe-auto {
  pointer-events: auto !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.rounded-1 {
  border-radius: 0.2rem !important;
}
.rounded-2 {
  border-radius: 0.25rem !important;
}
.rounded-3 {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.375rem !important;
  }
  .gap-sm-2 {
    gap: 0.75rem !important;
  }
  .gap-sm-3 {
    gap: 1.5rem !important;
  }
  .gap-sm-4 {
    gap: 2.25rem !important;
  }
  .gap-sm-5 {
    gap: 4.5rem !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2.25rem !important;
  }
  .m-sm-5 {
    margin: 4.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-sm-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-sm-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 2.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.375rem !important;
  }
  .me-sm-2 {
    margin-right: 0.75rem !important;
  }
  .me-sm-3 {
    margin-right: 1.5rem !important;
  }
  .me-sm-4 {
    margin-right: 2.25rem !important;
  }
  .me-sm-5 {
    margin-right: 4.5rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.375rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.75rem !important;
  }
  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-4 {
    margin-left: 2.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -2.25rem !important;
  }
  .m-sm-n5 {
    margin: -4.5rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -4.5rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .me-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n4 {
    margin-right: -2.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -4.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2.25rem !important;
  }
  .p-sm-5 {
    padding: 4.5rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-sm-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-sm-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-sm-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-sm-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 2.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 4.5rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-4 {
    padding-right: 2.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.375rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.75rem !important;
  }
  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-4 {
    padding-left: 2.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 4.5rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.375rem !important;
  }
  .gap-md-2 {
    gap: 0.75rem !important;
  }
  .gap-md-3 {
    gap: 1.5rem !important;
  }
  .gap-md-4 {
    gap: 2.25rem !important;
  }
  .gap-md-5 {
    gap: 4.5rem !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2.25rem !important;
  }
  .m-md-5 {
    margin: 4.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-md-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-md-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-md-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-md-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.375rem !important;
  }
  .mt-md-2 {
    margin-top: 0.75rem !important;
  }
  .mt-md-3 {
    margin-top: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 2.25rem !important;
  }
  .mt-md-5 {
    margin-top: 4.5rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.375rem !important;
  }
  .me-md-2 {
    margin-right: 0.75rem !important;
  }
  .me-md-3 {
    margin-right: 1.5rem !important;
  }
  .me-md-4 {
    margin-right: 2.25rem !important;
  }
  .me-md-5 {
    margin-right: 4.5rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.375rem !important;
  }
  .ms-md-2 {
    margin-left: 0.75rem !important;
  }
  .ms-md-3 {
    margin-left: 1.5rem !important;
  }
  .ms-md-4 {
    margin-left: 2.25rem !important;
  }
  .ms-md-5 {
    margin-left: 4.5rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -2.25rem !important;
  }
  .m-md-n5 {
    margin: -4.5rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-md-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-md-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-md-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -4.5rem !important;
  }
  .me-md-n1 {
    margin-right: -0.375rem !important;
  }
  .me-md-n2 {
    margin-right: -0.75rem !important;
  }
  .me-md-n3 {
    margin-right: -1.5rem !important;
  }
  .me-md-n4 {
    margin-right: -2.25rem !important;
  }
  .me-md-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-md-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -4.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2.25rem !important;
  }
  .p-md-5 {
    padding: 4.5rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-md-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-md-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-md-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-md-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.375rem !important;
  }
  .pt-md-2 {
    padding-top: 0.75rem !important;
  }
  .pt-md-3 {
    padding-top: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 2.25rem !important;
  }
  .pt-md-5 {
    padding-top: 4.5rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.375rem !important;
  }
  .pe-md-2 {
    padding-right: 0.75rem !important;
  }
  .pe-md-3 {
    padding-right: 1.5rem !important;
  }
  .pe-md-4 {
    padding-right: 2.25rem !important;
  }
  .pe-md-5 {
    padding-right: 4.5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.375rem !important;
  }
  .ps-md-2 {
    padding-left: 0.75rem !important;
  }
  .ps-md-3 {
    padding-left: 1.5rem !important;
  }
  .ps-md-4 {
    padding-left: 2.25rem !important;
  }
  .ps-md-5 {
    padding-left: 4.5rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.375rem !important;
  }
  .gap-lg-2 {
    gap: 0.75rem !important;
  }
  .gap-lg-3 {
    gap: 1.5rem !important;
  }
  .gap-lg-4 {
    gap: 2.25rem !important;
  }
  .gap-lg-5 {
    gap: 4.5rem !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2.25rem !important;
  }
  .m-lg-5 {
    margin: 4.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-lg-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-lg-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 2.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.375rem !important;
  }
  .me-lg-2 {
    margin-right: 0.75rem !important;
  }
  .me-lg-3 {
    margin-right: 1.5rem !important;
  }
  .me-lg-4 {
    margin-right: 2.25rem !important;
  }
  .me-lg-5 {
    margin-right: 4.5rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.375rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.75rem !important;
  }
  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-4 {
    margin-left: 2.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -2.25rem !important;
  }
  .m-lg-n5 {
    margin: -4.5rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -4.5rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .me-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n4 {
    margin-right: -2.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -4.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2.25rem !important;
  }
  .p-lg-5 {
    padding: 4.5rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-lg-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-lg-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-lg-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-lg-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 2.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 4.5rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-4 {
    padding-right: 2.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.375rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.75rem !important;
  }
  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-4 {
    padding-left: 2.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 4.5rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.375rem !important;
  }
  .gap-xl-2 {
    gap: 0.75rem !important;
  }
  .gap-xl-3 {
    gap: 1.5rem !important;
  }
  .gap-xl-4 {
    gap: 2.25rem !important;
  }
  .gap-xl-5 {
    gap: 4.5rem !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2.25rem !important;
  }
  .m-xl-5 {
    margin: 4.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 2.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xl-4 {
    margin-right: 2.25rem !important;
  }
  .me-xl-5 {
    margin-right: 4.5rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-4 {
    margin-left: 2.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -2.25rem !important;
  }
  .m-xl-n5 {
    margin: -4.5rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -4.5rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n4 {
    margin-right: -2.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -4.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2.25rem !important;
  }
  .p-xl-5 {
    padding: 4.5rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 2.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 4.5rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-4 {
    padding-right: 2.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-4 {
    padding-left: 2.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 4.5rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.375rem !important;
  }
  .gap-xxl-2 {
    gap: 0.75rem !important;
  }
  .gap-xxl-3 {
    gap: 1.5rem !important;
  }
  .gap-xxl-4 {
    gap: 2.25rem !important;
  }
  .gap-xxl-5 {
    gap: 4.5rem !important;
  }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly !important;
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 7 !important;
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.375rem !important;
  }
  .m-xxl-2 {
    margin: 0.75rem !important;
  }
  .m-xxl-3 {
    margin: 1.5rem !important;
  }
  .m-xxl-4 {
    margin: 2.25rem !important;
  }
  .m-xxl-5 {
    margin: 4.5rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
    margin-left: 0.375rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
    margin-left: 0.75rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-4 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.375rem !important;
    margin-bottom: 0.375rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }
  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.375rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.75rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.375rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.75rem !important;
  }
  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-4 {
    margin-right: 2.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.375rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.75rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.375rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.75rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-4 {
    margin-left: 2.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.375rem !important;
  }
  .m-xxl-n2 {
    margin: -0.75rem !important;
  }
  .m-xxl-n3 {
    margin: -1.5rem !important;
  }
  .m-xxl-n4 {
    margin: -2.25rem !important;
  }
  .m-xxl-n5 {
    margin: -4.5rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
    margin-left: -0.375rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
    margin-left: -0.75rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
    margin-bottom: -0.375rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.375rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.75rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -2.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -4.5rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.375rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.75rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n4 {
    margin-right: -2.25rem !important;
  }
  .me-xxl-n5 {
    margin-right: -4.5rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -2.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -4.5rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.375rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.75rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -2.25rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -4.5rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.375rem !important;
  }
  .p-xxl-2 {
    padding: 0.75rem !important;
  }
  .p-xxl-3 {
    padding: 1.5rem !important;
  }
  .p-xxl-4 {
    padding: 2.25rem !important;
  }
  .p-xxl-5 {
    padding: 4.5rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.375rem !important;
    padding-left: 0.375rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
  }
  .px-xxl-3 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-4 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.375rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.75rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 4.5rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.375rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.75rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-4 {
    padding-right: 2.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 4.5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 4.5rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.375rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.75rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-4 {
    padding-left: 2.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 4.5rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }
  .fs-2 {
    font-size: 1.875rem !important;
  }
  .fs-3 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
html {
  position: relative;
  min-height: 100%;
}
body {
  overflow-x: hidden;
}
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
.logo {
  display: block;
  line-height: 70px;
  width: 260px;
  position: fixed;
  top: 0;
}
.logo span.logo-lg {
  display: block;
}
.logo span.logo-sm {
  display: none;
}
.logo.logo-light {
  display: block;
}
.logo.logo-dark {
  display: none;
}
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.content-page {
  margin-left: 260px;
  overflow: hidden;
  padding: 70px 12px 65px;
  min-height: 70vh;
}
.leftside-menu {
  width: 260px;
  z-index: 10;
  bottom: 0;
  position: fixed;
  top: 0;
  padding-top: 70px;
}
.side-nav {
  padding-left: 0;
  list-style-type: none;
}
.side-nav ul {
  list-style-type: none;
}
.side-nav .side-nav-link {
  color: #cedce4;
  display: block;
  padding: 10px 30px;
  font-size: 0.9375rem;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  list-style: none;
}
.side-nav .side-nav-link:active,
.side-nav .side-nav-link:focus,
.side-nav .side-nav-link:hover {
  color: #fff;
  text-decoration: none;
}
.side-nav .side-nav-link span {
  vertical-align: middle;
}
.side-nav .side-nav-link i {
  display: inline-block;
  line-height: 1.0625rem;
  margin: 0 10px 0 0;
  font-size: 1.1rem;
  vertical-align: middle;
  width: 20px;
}
.side-nav .menu-arrow {
  -webkit-transition: -webkit-transform 0.15s;
  transition: -webkit-transform 0.15s;
  transition: transform 0.15s;
  transition: transform 0.15s, -webkit-transform 0.15s;
  position: absolute;
  right: 30px;
  display: inline-block;
  font-family: "Material Design Icons";
  text-rendering: auto;
  line-height: 1.5rem;
  font-size: 1.1rem;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.side-nav .menu-arrow:before {
  content: "\F0142";
}
.side-nav .badge {
  margin-top: 3px;
}
.side-nav .side-nav-item > a[aria-expanded="true"] > span.menu-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.side-nav .side-nav-item.menuitem-active > a:not(.collapsed) > span.menu-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.side-nav .side-nav-title {
  padding: 12px 30px;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 0.6875rem;
  text-transform: uppercase;
  color: #cedce4;
  font-weight: 700;
}
.side-nav .menuitem-active > a {
  color: #fff !important;
}
[dir="ltr"] .side-nav .side-nav-item .menu-arrow:before {
  content: "\F0141" !important;
}
.side-nav-forth-level,
.side-nav-second-level,
.side-nav-third-level {
  padding-left: 0;
}
.side-nav-forth-level li a,
.side-nav-second-level li a,
.side-nav-third-level li a {
  padding: 8px 30px 8px 65px;
  color: #cedce4;
  display: block;
  position: relative;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  font-size: 0.89rem;
}
.side-nav-forth-level li a:focus,
.side-nav-forth-level li a:hover,
.side-nav-second-level li a:focus,
.side-nav-second-level li a:hover,
.side-nav-third-level li a:focus,
.side-nav-third-level li a:hover {
  color: #fff;
}
.side-nav-forth-level li a .menu-arrow,
.side-nav-second-level li a .menu-arrow,
.side-nav-third-level li a .menu-arrow {
  line-height: 1.3rem;
}
.side-nav-forth-level li.active > a,
.side-nav-second-level li.active > a,
.side-nav-third-level li.active > a {
  color: #fff;
}
.side-nav-third-level li a {
  padding: 8px 30px 8px 80px;
}
.side-nav-forth-level li a {
  padding: 8px 30px 8px 100px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) {
  min-height: 1600px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .leftside-menu {
  position: absolute;
  padding-top: 0;
  width: 70px;
  z-index: 5;
  padding-top: 70px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .leftside-menu
  .simplebar-content-wrapper,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .leftside-menu
  .simplebar-mask {
  overflow: visible !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .leftside-menu
  .simplebar-scrollbar {
  display: none !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .leftside-menu
  .simplebar-offset {
  bottom: 0 !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .leftside-menu
  .logo {
  width: 70px;
  z-index: 1;
  background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .help-box {
  display: none;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .content-page {
  margin-left: 70px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .footer,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .wrapper
  .navbar-custom {
  left: 70px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .badge,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .menu-arrow,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-title {
  display: none !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapse,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapsing {
  display: none;
  height: inherit !important;
  -webkit-transition: none !important;
  transition: none !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapse
  .side-nav-forth-level,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapse
  .side-nav-second-level,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapse
  .side-nav-third-level,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapsing
  .side-nav-forth-level,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapsing
  .side-nav-second-level,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .collapsing
  .side-nav-third-level {
  display: none !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item {
  position: relative;
  white-space: nowrap;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link {
  padding: 15px 20px;
  min-height: 54px;
  -webkit-transition: none;
  transition: none;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link:active,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link:focus,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link:hover {
  color: #fff;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link
  i {
  font-size: 1.125rem;
  margin-right: 20px;
  margin-left: 6px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item
  .side-nav-link
  span {
  display: none;
  padding-left: 10px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  position: relative;
  width: 260px;
  color: #fff;
  background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  .side-nav-link
  span {
  display: inline;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > ul {
  display: block !important;
  left: 70px;
  position: absolute;
  width: 190px;
  height: auto !important;
  padding: 5px 0;
  z-index: 9999;
  background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > ul
  a {
  padding: 8px 20px;
  position: relative;
  width: 190px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > ul
  a:hover {
  color: #fff;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapse,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapsing {
  display: block !important;
  -webkit-transition: none !important;
  transition: none !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapse
  > ul,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapsing
  > ul {
  display: block !important;
  left: 70px;
  position: absolute;
  width: 190px;
  background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapse
  > ul
  a,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapsing
  > ul
  a {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 8px 20px;
  position: relative;
  width: 190px;
  z-index: 6;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapse
  > ul
  a:hover,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapsing
  > ul
  a:hover {
  color: #fff;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapse
  > ul
  li:hover
  > .collapse,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapsing
  > ul
  li:hover
  > .collapse {
  display: block !important;
  height: auto !important;
  -webkit-transition: none !important;
  transition: none !important;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapse
  > ul
  li:hover
  > .collapse
  > ul,
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .side-nav
  .side-nav-item:hover
  > .collapsing
  > ul
  li:hover
  > .collapse
  > ul {
  display: block;
  left: 190px;
  top: 0;
  position: absolute;
  width: 190px;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .logo
  span.logo-lg {
  display: none;
}
body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg)
  .logo
  span.logo-sm {
  display: block;
  line-height: 70px;
  color: var(--bs-indigo);
}
@media (max-width: 767.98px) {
  body {
    overflow-x: hidden;
  }
  .leftside-menu {
    -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
    box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
    display: none;
    z-index: 10 !important;
  }
  .sidebar-enable .leftside-menu {
    display: block;
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0 !important;
    padding: 65px 10px 65px;
  }
  body[data-leftbar-compact-mode="condensed"] .leftside-menu {
    margin-left: 0;
  }
  .logo span.logo-lg {
    display: block;
  }
  .logo span.logo-sm {
    display: none;
  }
}
.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
}
.help-box .close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

body[data-layout="detached"] {
  padding-bottom: 0;
}
@media (min-width: 992px) {
  body[data-layout="detached"] .container-fluid,
  body[data-layout="detached"] .container-lg,
  body[data-layout="detached"] .container-md,
  body[data-layout="detached"] .container-sm,
  body[data-layout="detached"] .container-xl,
  body[data-layout="detached"] .container-xxl {
    max-width: 95%;
  }
}
body[data-layout="detached"][data-layout-mode="boxed"] .wrapper {
  max-width: 100%;
}
body[data-layout="detached"] .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: inherit;
}
body[data-layout="detached"] .content-page {
  margin-left: 0;
  overflow: hidden;
  padding: 0 30px 5px 30px;
  position: relative;
  /* margin-right: -15px; */
  width: 100%;
  /* padding-bottom: 60px; */
}
body[data-layout="detached"] .leftside-menu {
  position: relative;
  background: var(--bs-gray-200) !important;
  min-width: 260px;
  max-width: 260px;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  margin-top: 30px;
  padding-top: 0 !important;
  z-index: 1001 !important;
}
body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link {
  color: var(--bs-gray-400) !important;
}
body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:active,
body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:focus,
body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:hover {
  color: #bccee4 !important;
}
body[data-layout="detached"]
  .leftside-menu
  .side-nav
  .side-nav-forth-level
  li
  a,
body[data-layout="detached"]
  .leftside-menu
  .side-nav
  .side-nav-second-level
  li
  a,
body[data-layout="detached"]
  .leftside-menu
  .side-nav
  .side-nav-third-level
  li
  a {
  color: var(--bs-gray-400);
}

body[data-layout="detached"] .leftside-menu .side-nav .menuitem-active > a {
  color: #cfe1f7 !important;
}
body[data-layout="detached"] .leftside-menu .side-nav .side-nav-title {
  color: var(--bs-gray-400);
}
body[data-layout="detached"] .leftbar-user {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}
body[data-layout="detached"] .leftbar-user .leftbar-user-name {
  font-weight: 700;
  color: var(--bs-gray-900);
  margin-top: 8px;
  display: block;
}
@media (max-width: 767.98px) {
  body[data-layout="detached"].sidebar-enable .leftside-menu {
    position: fixed;
    left: 0;
    overflow-y: auto;
    margin-top: 70px;
  }
  body[data-layout="detached"] .wrapper {
    max-width: 100%;
  }
  body[data-layout="detached"] .content-page {
    margin-left: 0 !important;
    padding: 0 10px 60px 10px;
  }
  body[data-layout="detached"] .lang-switch {
    display: none;
  }
  body[data-layout="detached"][data-leftbar-compact-mode="condensed"].sidebar-enable
    .leftside-menu {
    margin-top: 0;
  }
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .leftside-menu {
  max-width: 70px;
  min-width: 70px;
  position: relative;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .leftbar-user {
  display: none;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .content-page {
  margin-left: 0;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .wrapper
  .footer {
  left: 0;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:active,
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:focus,
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:hover {
  color: #fff;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  background: var(--bs-indigo);
  color: #fff !important;
  -webkit-transition: none;
  transition: none;
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  ul {
  background: #313a46 !important;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
}
body[data-layout="detached"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  ul
  a:hover {
  color: var(--bs-blue) !important;
}
@media (min-width: 992px) {
  body[data-layout="detached"][data-leftbar-compact-mode="scrollable"]
    .wrapper {
    padding-top: 70px;
  }
}
.button-menu-mobile .lines {
  width: 18px;
  display: block;
  position: relative;
  height: 16px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: 26px;
  margin-left: 10px;
}
.button-menu-mobile span {
  height: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.button-menu-mobile span:nth-of-type(2) {
  width: 24px;
}
.button-menu-mobile.disable-btn {
  display: none;
}
body[data-leftbar-theme="dark"] .leftside-menu {
  background: #fff;
}
body[data-leftbar-theme="dark"] .leftside-menu .logo {
  background: #fff !important;
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-link {
  color: var(--bs-gray-400);
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-link:active,
body[data-leftbar-theme="dark"] .side-nav .side-nav-link:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-link:hover {
  color: #bccee4;
}
body[data-leftbar-theme="dark"] .side-nav .menuitem-active > a {
  color: #fff !important;
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-title {
  color: var(--bs-gray-400);
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a {
  color: var(--bs-gray-400);
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:hover,
body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:hover,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:focus,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:hover {
  color: #bccee4;
}
body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li.active > a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li.active > a {
  color: #fff;
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:active,
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:focus,
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item
  .side-nav-link:hover {
  color: #bccee4;
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  .side-nav-link {
  background: var(--bs-indigo);
  color: #fff !important;
  -webkit-transition: none;
  transition: none;
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul {
  background: #fff;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > ul
  a:hover {
  color: #bccee4;
}
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > .collapse
  > ul,
body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"]
  .side-nav
  .side-nav-item:hover
  > .collapsing
  > ul {
  background: #fff;
}
.navbar-custom {
  padding: 0 24px;
  background-color: #3a444e;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  min-height: 70px;
  position: fixed;
  left: 260px;
  top: 0;
  right: 0;
  z-index: 1001;
}
.navbar-custom .topbar-left {
  background-color: #3a444e;
  height: 70px;
  position: fixed;
  z-index: 1;
  width: 260px;
  text-align: center;
  top: 0;
  left: 0;
}
.navbar-custom .topbar-left .logo {
  line-height: 70px;
}
.navbar-custom .topbar-left .logo i {
  display: none;
}
.navbar-custom .topbar-menu {
  position: relative;
  z-index: 1;
}
.navbar-custom .topbar-menu li {
  float: left;
  max-height: 70px;
}
.navbar-custom .topbar-menu li .show.nav-link {
  color: var(--bs-gray-700);
}
.navbar-custom .topbar-menu .nav-link {
  padding: 0;
  color: var(--bs-gray-600);
  min-width: 32px;
  display: block;
  text-align: center;
  margin: 0 10px;
  position: relative;
}
.navbar-custom .app-search {
  position: static;
  overflow-y: hidden;
}
.navbar-custom .app-search form {
  padding: calc(32px / 2) 5px calc(32px / 2) 0;
  overflow: hidden;
  max-width: 320px;
}
.topbar-dropdown .nav-link {
  line-height: 70px;
}
.app-search .form-control {
  border: none;
  height: calc(1.5em + 0.9rem + 2px);
  padding-left: 40px;
  padding-right: 20px;
  background-color: var(--bs-gray-300);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.app-search span.search-icon {
  position: absolute;
  z-index: 9;
  font-size: 20px;
  line-height: 38px;
  left: 10px;
  top: 0;
}
.app-search .input-group-text {
  margin-left: 0;
  z-index: 4;
}
.notification-list {
  margin-left: 0;
}
.notification-list .noti-title {
  background-color: transparent;
  padding: 15px 20px;
}
.notification-list .noti-icon {
  font-size: 22px;
  vertical-align: middle;
  line-height: 70px;
}
.notification-list .noti-icon-badge {
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 6px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: var(--bs-red);
}
.notification-list .notify-item {
  padding: 10px 20px;
}
.notification-list .notify-item .notify-icon {
  float: left;
  height: 36px;
  width: 36px;
  line-height: 36px;
  text-align: center;
  margin-right: 10px;
  border-radius: 50%;
  color: #fff;
}
.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  overflow: hidden;
  margin-left: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.notification-list .notify-item .notify-details b {
  font-weight: 500;
}
.notification-list .notify-item .notify-details .small,
.notification-list .notify-item .notify-details small {
  display: block;
}
.notification-list .notify-item .notify-details span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.notification-list .notify-item .user-msg {
  margin-left: 45px;
  white-space: normal;
  line-height: 16px;
}
.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}
.profile-dropdown {
  min-width: 170px;
}
.profile-dropdown i,
.profile-dropdown span {
  vertical-align: middle;
}
.nav-user {
  padding: calc(31px / 2) 20px calc(31px / 2) 57px !important;
  text-align: left !important;
  position: relative;
  background-color: var(--bs-gray-300);
  border: 1px solid var(--bs-gray-100);
  border-width: 0 1px;
  min-height: 70px;
}
.nav-user .account-user-avatar {
  position: absolute;
  top: calc(38px / 2);
  left: 15px;
}
.nav-user .account-user-avatar img {
  height: 32px;
  width: 32px;
}
.nav-user .account-position {
  display: block;
  font-size: 12px;
  margin-top: -3px;
}
.nav-user .account-user-name {
  display: block;
  font-weight: 600;
}
.button-menu-mobile {
  border: none;
  color: var(--bs-gray-900);
  height: 70px;
  line-height: 70px;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;
  z-index: 1;
  position: relative;
  margin-left: -24px;
}
.button-menu-mobile.disable-btn {
  display: none;
}
@media (max-width: 767.98px) {
  .button-menu-mobile {
    margin: 0 !important;
  }
}
[data-keep-enlarged="true"] .navbar-custom {
  padding-left: 0;
}
[data-keep-enlarged="true"] .button-menu-mobile {
  margin: 0;
}
[data-keep-enlarged="true"] .button-menu-mobile.disable-btn {
  display: inline-block;
}
@media (max-width: 600px) {
  .navbar-custom .topbar-menu {
    position: initial;
  }
  .navbar-custom .dropdown {
    position: static;
  }
  .navbar-custom .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}
@media (max-width: 1200px) {
  .navbar-custom {
    right: 0;
  }
  .navbar-custom .app-search {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .nav-user {
    padding: 17px 5px 17px 57px !important;
  }
  .nav-user .account-position,
  .nav-user .account-user-name {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-custom {
    left: 0 !important;
    padding: 0;
  }
  .button-menu-mobile.disable-btn {
    display: inline-block;
  }
}
@media (max-width: 375px) {
  .navbar-custom .topbar-menu .nav-link {
    margin: 0 6px;
  }
  .navbar-custom.topnav-navbar .container-fluid,
  .navbar-custom.topnav-navbar .container-lg,
  .navbar-custom.topnav-navbar .container-md,
  .navbar-custom.topnav-navbar .container-sm,
  .navbar-custom.topnav-navbar .container-xl,
  .navbar-custom.topnav-navbar .container-xxl {
    padding-right: 12px;
    padding-left: 12px;
  }
  .navbar-custom.topnav-navbar .navbar-toggle {
    margin: 27px 3px;
  }
  .navbar-custom.topnav-navbar .button-menu-mobile {
    width: auto;
  }
}
.topnav-navbar-dark {
  background-color: #3a444e;
}
.topnav-navbar-dark .nav-user {
  background-color: #45515d;
  border: 1px solid #4a5764;
}
.topnav-navbar-dark .topbar-menu li .show.nav-link {
  color: #fff !important;
}
.topnav-navbar-dark .app-search .form-control {
  background-color: var(--bs-gray-300);
  color: #fff;
}
.topnav-navbar-dark .app-search span {
  color: var(--bs-gray-600);
}
.topnav-navbar-dark .navbar-toggle span {
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.end-bar {
  background-color: var(--bs-gray-200);
  -webkit-box-shadow: 0 0 24px 0 rgba(241, 241, 241, 0.1),
    0 1px 0 0 rgba(241, 241, 241, 0.08);
  box-shadow: 0 0 24px 0 rgba(241, 241, 241, 0.1),
    0 1px 0 0 rgba(241, 241, 241, 0.08);
  display: block;
  position: fixed;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 280px;
  z-index: 9999;
  float: right !important;
  right: -290px;
  top: 0;
  bottom: 0;
  padding-bottom: 80px;
}
.end-bar .rightbar-title {
  background-color: var(--bs-blue);
  padding: 27px 25px;
  color: #fff;
}
.end-bar .end-bar-toggle {
  background-color: #5383e1;
  height: 24px;
  width: 24px;
  line-height: 22px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  margin-top: -4px;
}
.rightbar-overlay {
  background-color: #000;
  opacity: 0.1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 9998;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.end-bar-enabled .end-bar {
  right: 0;
}
.end-bar-enabled .rightbar-overlay {
  display: block;
}
@media (max-width: 767.98px) {
  .end-bar {
    overflow: auto;
  }
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  line-height: 75px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #fff;
}
.page-title-box .page-title-right {
  float: right;
  margin-top: 20px;
}
.page-title-box-sm .page-title {
  line-height: 1 !important;
  margin-bottom: 25px;
}
.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0;
}
.text-title {
  color: #fff;
}
.text-title:hover {
  color: #fff;
}
@media (max-width: 767.98px) {
  .page-title-box .page-title {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 70px;
  }
  .page-title-box .page-title-right {
    display: none;
  }
}
.footer {
  border-top: 1px solid rgba(206, 212, 218, 0.2);
  bottom: 0;
  padding: 19px 24px 20px;
  /* position: absolute; */
  right: 0;
  color: var(--bs-gray-600);
  left: 260px;
}
.footer .footer-links a {
  color: var(--bs-gray-600);
  margin-left: 1.5rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.footer .footer-links a:hover {
  color: var(--bs-gray-900);
}
.footer .footer-links a:first-of-type {
  margin-left: 0;
}
@media (max-width: 767.98px) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}
.footer-alt {
  left: 0;
  border: none;
  text-align: center;
}
body[data-layout="detached"] .footer {
  left: 0;
}
body[data-layout="detached"] .footer .container-fluid,
body[data-layout="detached"] .footer .container-lg,
body[data-layout="detached"] .footer .container-md,
body[data-layout="detached"] .footer .container-sm,
body[data-layout="detached"] .footer .container-xl,
body[data-layout="detached"] .footer .container-xxl {
  max-width: 100%;
  padding: 0;
}
.topnav-navbar {
  padding: 0;
  margin: 0;
  min-height: 70px;
  position: relative;
  left: 0 !important;
  z-index: 1002;
}
.topnav-navbar .topnav-logo {
  line-height: 70px;
  float: left;
  padding-right: 20px;
  min-width: 160px;
}
.topnav-navbar .topnav-logo .topnav-logo-sm {
  display: none;
}
.topnav-navbar .navbar-toggle {
  position: relative;
  cursor: pointer;
  float: left;
  margin: 27px 20px;
  padding: 0;
  background-color: transparent;
  border: none;
}
.topnav-navbar .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  height: 16px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.topnav-navbar .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: rgba(241, 241, 241, 0.8);
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.topnav-navbar .navbar-toggle.open span {
  position: absolute;
}
.topnav-navbar .navbar-toggle.open span:first-child {
  top: 7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.topnav-navbar .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
.topnav-navbar .navbar-toggle.open span:last-child {
  width: 100%;
  top: 7px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.topnav-navbar .app-search {
  float: left;
}
.topnav {
  background: var(--bs-gray-200);
}
.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}
.topnav .navbar-nav .nav-link {
  font-size: 0.9375rem;
  position: relative;
  padding: 1rem 1.3rem;
}
.topnav .nav-item.active > a {
  color: var(--bs-blue);
}
.topnav .navbar-dark .dropdown.active > .nav-link,
.topnav .navbar-dark .dropdown:active > .nav-link {
  color: #fff;
}
.arrow-down {
  display: inline-block;
}
.arrow-down:after {
  border-color: initial;
  border-style: solid;
  border-width: 0 0 1px 1px;
  content: "";
  height: 0.4em;
  display: inline-block;
  right: 5px;
  top: 50%;
  margin-left: 10px;
  -webkit-transform: rotate(-45deg) translateY(-50%);
  transform: rotate(-45deg) translateY(-50%);
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 0.4em;
}
.custom-accordion .card {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custom-accordion .card-header {
  background-color: var(--bs-gray-100);
}
.custom-accordion .card-body {
  border: 1px solid var(--bs-gray-100);
}
.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0;
}
.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "\F142";
}
.custom-accordion-title {
  color: var(--bs-gray-400);
  position: relative;
}
.custom-accordion-title:hover {
  color: #99a4b2;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.btn .mdi:before {
  line-height: initial;
}
.btn-primary {
  -webkit-box-shadow: 0 2px 6px 0 rgba(83, 109, 230, 0.5);
  box-shadow: 0 2px 6px 0 rgba(83, 109, 230, 0.5);
}
.btn-secondary {
  -webkit-box-shadow: 0 2px 6px 0 rgba(206, 212, 218, 0.5);
  box-shadow: 0 2px 6px 0 rgba(206, 212, 218, 0.5);
}
.btn-success {
  -webkit-box-shadow: 0 2px 6px 0 rgba(16, 196, 105, 0.5);
  box-shadow: 0 2px 6px 0 rgba(16, 196, 105, 0.5);
}
.btn-info {
  -webkit-box-shadow: 0 2px 6px 0 rgba(53, 184, 224, 0.5);
  box-shadow: 0 2px 6px 0 rgba(53, 184, 224, 0.5);
}
.btn-warning {
  -webkit-box-shadow: 0 2px 6px 0 rgba(249, 200, 81, 0.5);
  box-shadow: 0 2px 6px 0 rgba(249, 200, 81, 0.5);
}
.btn-danger {
  -webkit-box-shadow: 0 2px 6px 0 rgba(255, 91, 91, 0.5);
  box-shadow: 0 2px 6px 0 rgba(255, 91, 91, 0.5);
}
.btn-light {
  -webkit-box-shadow: 0 2px 6px 0 rgba(70, 79, 91, 0.5);
  box-shadow: 0 2px 6px 0 rgba(70, 79, 91, 0.5);
}
.btn-dark {
  -webkit-box-shadow: 0 2px 6px 0 rgba(241, 241, 241, 0.5);
  box-shadow: 0 2px 6px 0 rgba(241, 241, 241, 0.5);
}
.badge-primary-lighten {
  color: var(--bs-indigo);
  background-color: rgba(83, 109, 230, 0.18);
}
.badge-primary-lighten[href] {
  color: var(--bs-indigo);
  text-decoration: none;
  background-color: rgba(83, 109, 230, 0.18);
}
.badge-primary-lighten[href]:focus,
.badge-primary-lighten[href]:hover {
  color: var(--bs-indigo);
  text-decoration: none;
  background-color: rgba(83, 109, 230, 0.4);
}
.badge-secondary-lighten {
  color: var(--bs-gray-600);
  background-color: rgba(206, 212, 218, 0.18);
}
.badge-secondary-lighten[href] {
  color: var(--bs-gray-600);
  text-decoration: none;
  background-color: rgba(206, 212, 218, 0.18);
}
.badge-secondary-lighten[href]:focus,
.badge-secondary-lighten[href]:hover {
  color: var(--bs-gray-600);
  text-decoration: none;
  background-color: rgba(206, 212, 218, 0.4);
}
.badge-success-lighten {
  color: var(--bs-green);
  background-color: rgba(16, 196, 105, 0.18);
}
.badge-success-lighten[href] {
  color: var(--bs-green);
  text-decoration: none;
  background-color: rgba(16, 196, 105, 0.18);
}
.badge-success-lighten[href]:focus,
.badge-success-lighten[href]:hover {
  color: var(--bs-green);
  text-decoration: none;
  background-color: rgba(16, 196, 105, 0.4);
}
.badge-info-lighten {
  color: var(--bs-cyan);
  background-color: rgba(53, 184, 224, 0.18);
}
.badge-info-lighten[href] {
  color: var(--bs-cyan);
  text-decoration: none;
  background-color: rgba(53, 184, 224, 0.18);
}
.badge-info-lighten[href]:focus,
.badge-info-lighten[href]:hover {
  color: var(--bs-cyan);
  text-decoration: none;
  background-color: rgba(53, 184, 224, 0.4);
}
.badge-warning-lighten {
  color: var(--bs-yellow);
  background-color: rgba(249, 200, 81, 0.18);
}
.badge-warning-lighten[href] {
  color: var(--bs-yellow);
  text-decoration: none;
  background-color: rgba(249, 200, 81, 0.18);
}
.badge-warning-lighten[href]:focus,
.badge-warning-lighten[href]:hover {
  color: var(--bs-yellow);
  text-decoration: none;
  background-color: rgba(249, 200, 81, 0.4);
}
.badge-danger-lighten {
  color: var(--bs-red);
  background-color: rgba(255, 91, 91, 0.18);
}
.badge-danger-lighten[href] {
  color: var(--bs-red);
  text-decoration: none;
  background-color: rgba(255, 91, 91, 0.18);
}
.badge-danger-lighten[href]:focus,
.badge-danger-lighten[href]:hover {
  color: var(--bs-red);
  text-decoration: none;
  background-color: rgba(255, 91, 91, 0.4);
}
.badge-light-lighten {
  color: var(--bs-gray-300);
  background-color: rgba(70, 79, 91, 0.18);
}
.badge-light-lighten[href] {
  color: var(--bs-gray-300);
  text-decoration: none;
  background-color: rgba(70, 79, 91, 0.18);
}
.badge-light-lighten[href]:focus,
.badge-light-lighten[href]:hover {
  color: var(--bs-gray-300);
  text-decoration: none;
  background-color: rgba(70, 79, 91, 0.4);
}
.badge-dark-lighten {
  color: var(--bs-gray-900);
  background-color: rgba(241, 241, 241, 0.18);
}
.badge-dark-lighten[href] {
  color: var(--bs-gray-900);
  text-decoration: none;
  background-color: rgba(241, 241, 241, 0.18);
}
.badge-dark-lighten[href]:focus,
.badge-dark-lighten[href]:hover {
  color: var(--bs-gray-900);
  text-decoration: none;
  background-color: rgba(241, 241, 241, 0.4);
}
.badge-outline-primary {
  color: var(--bs-indigo);
  border: 1px solid var(--bs-indigo);
  background-color: transparent;
}
.badge-outline-primary[href] {
  color: var(--bs-indigo);
  text-decoration: none;
  background-color: rgba(83, 109, 230, 0.2);
}
.badge-outline-primary[href]:focus,
.badge-outline-primary[href]:hover {
  color: var(--bs-indigo);
  text-decoration: none;
  background-color: rgba(83, 109, 230, 0.2);
}
.badge-outline-secondary {
  color: var(--bs-gray-600);
  border: 1px solid var(--bs-gray-600);
  background-color: transparent;
}
.badge-outline-secondary[href] {
  color: var(--bs-gray-600);
  text-decoration: none;
  background-color: rgba(206, 212, 218, 0.2);
}
.badge-outline-secondary[href]:focus,
.badge-outline-secondary[href]:hover {
  color: var(--bs-gray-600);
  text-decoration: none;
  background-color: rgba(206, 212, 218, 0.2);
}
.badge-outline-success {
  color: var(--bs-green);
  border: 1px solid var(--bs-green);
  background-color: transparent;
}
.badge-outline-success[href] {
  color: var(--bs-green);
  text-decoration: none;
  background-color: rgba(16, 196, 105, 0.2);
}
.badge-outline-success[href]:focus,
.badge-outline-success[href]:hover {
  color: var(--bs-green);
  text-decoration: none;
  background-color: rgba(16, 196, 105, 0.2);
}
.badge-outline-info {
  color: var(--bs-cyan);
  border: 1px solid var(--bs-cyan);
  background-color: transparent;
}
.badge-outline-info[href] {
  color: var(--bs-cyan);
  text-decoration: none;
  background-color: rgba(53, 184, 224, 0.2);
}
.badge-outline-info[href]:focus,
.badge-outline-info[href]:hover {
  color: var(--bs-cyan);
  text-decoration: none;
  background-color: rgba(53, 184, 224, 0.2);
}
.badge-outline-warning {
  color: var(--bs-yellow);
  border: 1px solid var(--bs-yellow);
  background-color: transparent;
}
.badge-outline-warning[href] {
  color: var(--bs-yellow);
  text-decoration: none;
  background-color: rgba(249, 200, 81, 0.2);
}
.badge-outline-warning[href]:focus,
.badge-outline-warning[href]:hover {
  color: var(--bs-yellow);
  text-decoration: none;
  background-color: rgba(249, 200, 81, 0.2);
}
.badge-outline-danger {
  color: var(--bs-red);
  border: 1px solid var(--bs-red);
  background-color: transparent;
}
.badge-outline-danger[href] {
  color: var(--bs-red);
  text-decoration: none;
  background-color: rgba(255, 91, 91, 0.2);
}
.badge-outline-danger[href]:focus,
.badge-outline-danger[href]:hover {
  color: var(--bs-red);
  text-decoration: none;
  background-color: rgba(255, 91, 91, 0.2);
}
.badge-outline-light {
  color: var(--bs-gray-300);
  border: 1px solid var(--bs-gray-300);
  background-color: transparent;
}
.badge-outline-light[href] {
  color: var(--bs-gray-300);
  text-decoration: none;
  background-color: rgba(70, 79, 91, 0.2);
}
.badge-outline-light[href]:focus,
.badge-outline-light[href]:hover {
  color: var(--bs-gray-300);
  text-decoration: none;
  background-color: rgba(70, 79, 91, 0.2);
}
.badge-outline-dark {
  color: var(--bs-gray-900);
  border: 1px solid var(--bs-gray-900);
  background-color: transparent;
}
.badge-outline-dark[href] {
  color: var(--bs-gray-900);
  text-decoration: none;
  background-color: rgba(241, 241, 241, 0.2);
}
.badge-outline-dark[href]:focus,
.badge-outline-dark[href]:hover {
  color: var(--bs-gray-900);
  text-decoration: none;
  background-color: rgba(241, 241, 241, 0.2);
}
.card {
  border: none;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  margin-bottom: 24px;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
}
.card .card-drop {
  font-size: 20px;
  line-height: 0;
  color: inherit;
}
.card .card-widgets {
  float: right;
  height: 16px;
}
.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
}
.card .card-widgets > a.collapsed i:before {
  content: "\F0415";
}
.card-header,
.card-title {
  margin-top: 0;
}
.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.8);
  cursor: progress;
}
.card-disabled .card-portlets-loader {
  background-color: #313a46;
  -webkit-animation: rotatebox 1.2s infinite ease-in-out;
  animation: rotatebox 1.2s infinite ease-in-out;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
@-webkit-keyframes rotatebox {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes rotatebox {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
    transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.card-pricing {
  position: relative;
}
.card-pricing .card-pricing-plan-name {
  padding-bottom: 20px;
}
.card-pricing .card-pricing-icon {
  font-size: 22px;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 56px;
  border-radius: 50%;
}
.card-pricing .card-pricing-price {
  padding: 30px 0 0;
}
.card-pricing .card-pricing-price span {
  font-size: 40%;
  color: var(--bs-gray-600);
  letter-spacing: 2px;
  text-transform: uppercase;
}
.card-pricing .card-pricing-features {
  color: var(--bs-gray-600);
  list-style: none;
  margin: 0;
  padding: 20px 0 0 0;
}
.card-pricing .card-pricing-features li {
  padding: 15px;
}
@media (min-width: 992px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem;
  }
}
.card-pricing-recommended .card-pricing-plan-tag {
  background-color: rgba(255, 91, 91, 0.2);
  color: var(--bs-red);
  padding: 5px 0;
  font-weight: 700;
  border-radius: 0.25rem 0.25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}
.card-h-100 {
  height: calc(100% - 24px);
}
.form-checkbox-primary .form-check-input:checked,
.form-radio-primary .form-check-input:checked {
  background-color: var(--bs-indigo);
  border-color: var(--bs-indigo);
}
.form-checkbox-secondary .form-check-input:checked,
.form-radio-secondary .form-check-input:checked {
  background-color: var(--bs-gray-600);
  border-color: var(--bs-gray-600);
}
.form-checkbox-success .form-check-input:checked,
.form-radio-success .form-check-input:checked {
  background-color: var(--bs-green);
  border-color: var(--bs-green);
}
.form-checkbox-info .form-check-input:checked,
.form-radio-info .form-check-input:checked {
  background-color: var(--bs-cyan);
  border-color: var(--bs-cyan);
}
.form-checkbox-warning .form-check-input:checked,
.form-radio-warning .form-check-input:checked {
  background-color: var(--bs-yellow);
  border-color: var(--bs-yellow);
}
.form-checkbox-danger .form-check-input:checked,
.form-radio-danger .form-check-input:checked {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}
.form-checkbox-light .form-check-input:checked,
.form-radio-light .form-check-input:checked {
  background-color: var(--bs-gray-300);
  border-color: var(--bs-gray-300);
}
.form-checkbox-dark .form-check-input:checked,
.form-radio-dark .form-check-input:checked {
  background-color: var(--bs-gray-900);
  border-color: var(--bs-gray-900);
}
.dropdown-menu {
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
}
.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto !important;
  right: 0 !important;
}
.dropdown-menu-animated {
  -webkit-animation-name: DropDownSlide;
  animation-name: DropDownSlide;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000;
}
.dropdown-menu-animated.show {
  top: 100% !important;
}
.dropdown-menu-animated i {
  display: inline-block;
}
.dropdown-menu-animated.dropdown-menu[data-popper-placement^="left"],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^="top"] {
  top: auto !important;
  -webkit-animation: none !important;
  animation: none !important;
}
@-webkit-keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@keyframes DropDownSlide {
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}
@media (min-width: 600px) {
  .dropdown-lg {
    width: 320px;
  }
}
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: var(--bs-gray-500);
}
.dropdown-icon-item img {
  height: 24px;
}
.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropdown-icon-item:hover {
  background-color: #4d5764;
  color: #d9d9d9;
}
.arrow-none:after {
  display: none;
}
.hljs {
  display: block;
  overflow-x: auto;
  padding: 2em;
  color: var(--bs-gray-900);
  max-height: 420px;
  margin: -10px 0 -30px;
  border: 1px solid rgba(206, 212, 218, 0.2);
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: var(--bs-gray-900);
  font-weight: 700;
}
.hljs-literal,
.hljs-number,
.hljs-tag .hljs-attr,
.hljs-template-variable,
.hljs-variable {
  color: var(--bs-teal);
}
.hljs-doctag,
.hljs-string {
  color: var(--bs-red);
}
.hljs-section,
.hljs-selector-id,
.hljs-title {
  color: var(--bs-red);
  font-weight: 700;
}
.hljs-subst {
  font-weight: 400;
}
.hljs-attribute,
.hljs-name,
.hljs-tag {
  color: #1f4faf;
  font-weight: 400;
}
.hljs-link,
.hljs-regexp {
  color: var(--bs-teal);
}
.hljs-bullet,
.hljs-symbol {
  color: var(--bs-purple);
}
.hljs-built_in,
.hljs-builtin-name {
  color: var(--bs-cyan);
}
.hljs-meta {
  color: var(--bs-gray-800);
  font-weight: 700;
}
.hljs-deletion {
  background: #fdd;
}
.hljs-addition {
  background: #dfd;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: 700;
}
.hljs-comment {
  color: var(--bs-gray-400);
}
.form-control-light {
  background-color: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-100) !important;
}
input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px;
}
.custom-select.is-invalid:focus,
.custom-select.is-valid:focus,
.custom-select:invalid:focus,
.custom-select:valid:focus,
.form-control.is-invalid:focus,
.form-control.is-valid:focus,
.form-control:invalid:focus,
.form-control:valid:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + 0.9rem + 2px);
}
select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.5em + 0.56rem + 2px);
}
.password-eye:before {
  font-family: "Material Design Icons";
  content: "\F06D0";
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  vertical-align: middle;
  line-height: 1.2;
  font-size: 16px;
}
.show-password .password-eye:before {
  content: "\F06D1";
}
.modal-title {
  margin-top: 0;
}
.modal-full-width {
  width: 95%;
  max-width: none;
}
.modal-top {
  margin: 0 auto;
}
.modal-right {
  position: absolute;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  margin: 0;
  background-color: var(--bs-gray-300);
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-transform: translate(25%, 0) !important;
  transform: translate(25%, 0) !important;
}
.modal-right button.btn-close {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.modal.show .modal-left,
.modal.show .modal-right {
  -webkit-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}
.modal-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  height: 100%;
  margin: 0 auto;
  -ms-flex-line-pack: center;
  align-content: center;
}
.modal-colored-header {
  color: #fff;
  border-radius: 0;
}
.modal-colored-header .btn-close {
  color: #fff !important;
}
.modal-filled {
  color: #fff;
}
.modal-filled .modal-header {
  background-color: rgba(255, 255, 255, 0.07);
}
.modal-filled .modal-footer,
.modal-filled .modal-header {
  border: none;
}
.modal-filled .btn-close {
  color: #fff !important;
}
body.modal-open,
body.show {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.nav-pills > li > a,
.nav-tabs > li > a {
  color: var(--bs-gray-700);
  font-weight: 600;
}
.nav-pills > a {
  color: var(--bs-gray-700);
  font-weight: 600;
}
.bg-nav-pills {
  background-color: var(--bs-gray-100);
}
.nav-tabs.nav-bordered {
  border-bottom: 2px solid rgba(206, 212, 218, 0.2);
}
.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -1px;
}
.nav-tabs.nav-bordered li a {
  border: 0;
  padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid var(--bs-indigo);
}
.pagination-rounded .page-link {
  border-radius: 30px !important;
  margin: 0 3px !important;
  border: none;
}
.popover-header {
  margin-top: 0;
}
@media print {
  .end-bar,
  .footer,
  .leftside-menu,
  .navbar-custom,
  .page-title-box {
    display: none;
  }
  .card-body,
  .content,
  .content-page,
  .end-bar,
  body {
    padding: 0;
    margin: 0;
  }
}
.progress-sm {
  height: 5px;
}
.progress-md {
  height: 8px;
}
.progress-lg {
  height: 12px;
}
.progress-xl {
  height: 15px;
}
body.loading {
  visibility: hidden;
}
a,
button {
  outline: 0 !important;
}
label {
  font-weight: 600;
}
address.address-lg {
  line-height: 24px;
}
b,
strong {
  font-weight: 700;
}
.ribbon-box {
  position: relative;
}
.ribbon-box .ribbon {
  position: relative;
  clear: both;
  padding: 5px 12px;
  margin-bottom: 15px;
  -webkit-box-shadow: 2px 5px 10px rgba(241, 241, 241, 0.15);
  box-shadow: 2px 5px 10px rgba(241, 241, 241, 0.15);
  color: #fff;
  font-size: 13px;
  font-weight: 600;
}
.ribbon-box .ribbon:before {
  content: " ";
  border-style: solid;
  border-width: 10px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
  margin-bottom: -10px;
  z-index: -1;
}
.ribbon-box .ribbon.float-start {
  margin-left: -30px;
  border-radius: 0 3px 3px 0;
}
.ribbon-box .ribbon.float-end {
  margin-right: -30px;
  border-radius: 3px 0 0 3px;
}
.ribbon-box .ribbon.float-end:before {
  right: 0;
}
.ribbon-box .ribbon.float-center span {
  margin: 0 auto 20px auto;
}
.ribbon-box .ribbon-content {
  clear: both;
}
.ribbon-box .ribbon-primary {
  background: var(--bs-indigo);
}
.ribbon-box .ribbon-primary:before {
  border-color: #3d5ae3 transparent transparent;
}
.ribbon-box .ribbon-secondary {
  background: var(--bs-gray-600);
}
.ribbon-box .ribbon-secondary:before {
  border-color: #bfc7cf transparent transparent;
}
.ribbon-box .ribbon-success {
  background: var(--bs-green);
}
.ribbon-box .ribbon-success:before {
  border-color: #0eac5c transparent transparent;
}
.ribbon-box .ribbon-info {
  background: var(--bs-cyan);
}
.ribbon-box .ribbon-info:before {
  border-color: #21afda transparent transparent;
}
.ribbon-box .ribbon-warning {
  background: var(--bs-yellow);
}
.ribbon-box .ribbon-warning:before {
  border-color: #f8c038 transparent transparent;
}
.ribbon-box .ribbon-danger {
  background: var(--bs-red);
}
.ribbon-box .ribbon-danger:before {
  border-color: #ff4242 transparent transparent;
}
.ribbon-box .ribbon-light {
  background: var(--bs-gray-300);
}
.ribbon-box .ribbon-light:before {
  border-color: #3b424d transparent transparent;
}
.ribbon-box .ribbon-dark {
  background: var(--bs-gray-900);
}
.ribbon-box .ribbon-dark:before {
  border-color: #e4e4e4 transparent transparent;
}
.ribbon-box .ribbon-two {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon-box .ribbon-two span {
  font-size: 13px;
  color: #fff;
  text-align: center;
  line-height: 20px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 100px;
  display: block;
  -webkit-box-shadow: 0 0 8px 0 rgba(241, 241, 241, 0.08),
    0 1px 0 0 rgba(241, 241, 241, 0.03);
  box-shadow: 0 0 8px 0 rgba(241, 241, 241, 0.08),
    0 1px 0 0 rgba(241, 241, 241, 0.03);
  position: absolute;
  top: 19px;
  left: -21px;
  font-weight: 600;
}
.ribbon-box .ribbon-two span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  z-index: -1;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two span:after {
  content: "";
  position: absolute;
  right: 0;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two-primary span {
  background: var(--bs-indigo);
}
.ribbon-box .ribbon-two-primary span:before {
  border-left: 3px solid #3d5ae3;
  border-top: 3px solid #3d5ae3;
}
.ribbon-box .ribbon-two-primary span:after {
  border-right: 3px solid #3d5ae3;
  border-top: 3px solid #3d5ae3;
}
.ribbon-box .ribbon-two-secondary span {
  background: var(--bs-gray-600);
}
.ribbon-box .ribbon-two-secondary span:before {
  border-left: 3px solid #bfc7cf;
  border-top: 3px solid #bfc7cf;
}
.ribbon-box .ribbon-two-secondary span:after {
  border-right: 3px solid #bfc7cf;
  border-top: 3px solid #bfc7cf;
}
.ribbon-box .ribbon-two-success span {
  background: var(--bs-green);
}
.ribbon-box .ribbon-two-success span:before {
  border-left: 3px solid #0eac5c;
  border-top: 3px solid #0eac5c;
}
.ribbon-box .ribbon-two-success span:after {
  border-right: 3px solid #0eac5c;
  border-top: 3px solid #0eac5c;
}
.ribbon-box .ribbon-two-info span {
  background: var(--bs-cyan);
}
.ribbon-box .ribbon-two-info span:before {
  border-left: 3px solid #21afda;
  border-top: 3px solid #21afda;
}
.ribbon-box .ribbon-two-info span:after {
  border-right: 3px solid #21afda;
  border-top: 3px solid #21afda;
}
.ribbon-box .ribbon-two-warning span {
  background: var(--bs-yellow);
}
.ribbon-box .ribbon-two-warning span:before {
  border-left: 3px solid #f8c038;
  border-top: 3px solid #f8c038;
}
.ribbon-box .ribbon-two-warning span:after {
  border-right: 3px solid #f8c038;
  border-top: 3px solid #f8c038;
}
.ribbon-box .ribbon-two-danger span {
  background: var(--bs-red);
}
.ribbon-box .ribbon-two-danger span:before {
  border-left: 3px solid #ff4242;
  border-top: 3px solid #ff4242;
}
.ribbon-box .ribbon-two-danger span:after {
  border-right: 3px solid #ff4242;
  border-top: 3px solid #ff4242;
}
.ribbon-box .ribbon-two-light span {
  background: var(--bs-gray-300);
}
.ribbon-box .ribbon-two-light span:before {
  border-left: 3px solid #3b424d;
  border-top: 3px solid #3b424d;
}
.ribbon-box .ribbon-two-light span:after {
  border-right: 3px solid #3b424d;
  border-top: 3px solid #3b424d;
}
.ribbon-box .ribbon-two-dark span {
  background: var(--bs-gray-900);
}
.ribbon-box .ribbon-two-dark span:before {
  border-left: 3px solid #e4e4e4;
  border-top: 3px solid #e4e4e4;
}
.ribbon-box .ribbon-two-dark span:after {
  border-right: 3px solid #e4e4e4;
  border-top: 3px solid #e4e4e4;
}
input[data-switch] {
  display: none;
}
input[data-switch] + label {
  width: 56px;
  height: 24px;
  background-color: var(--bs-gray-100);
  background-image: none;
  border-radius: 2rem;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:before {
  color: var(--bs-gray-900);
  content: attr(data-off-label);
  display: block;
  font-family: inherit;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  position: absolute;
  right: 3px;
  margin: 0 0.21667rem;
  top: 0;
  text-align: center;
  min-width: 1.66667rem;
  overflow: hidden;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[data-switch] + label:after {
  content: "";
  position: absolute;
  left: 4px;
  background-color: var(--bs-gray-500);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2rem;
  height: 18px;
  width: 18px;
  top: 3px;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
input[data-switch]:checked + label {
  background-color: var(--bs-indigo);
}
input[data-switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 4px;
}
input[data-switch]:checked + label:after {
  left: 34px;
  background-color: var(--bs-gray-100);
}
input[data-switch="bool"] + label {
  background-color: var(--bs-red);
}
input:disabled + label {
  opacity: 0.5;
  cursor: default;
}
input[data-switch="bool"] + label:before,
input[data-switch="bool"]:checked + label:before {
  color: #fff !important;
}
input[data-switch="bool"] + label:after {
  background-color: var(--bs-gray-100);
}
input[data-switch="primary"]:checked + label {
  background-color: var(--bs-indigo);
}
input[data-switch="secondary"]:checked + label {
  background-color: var(--bs-gray-600);
}
input[data-switch="success"]:checked + label {
  background-color: var(--bs-green);
}
input[data-switch="info"]:checked + label {
  background-color: var(--bs-cyan);
}
input[data-switch="warning"]:checked + label {
  background-color: var(--bs-yellow);
}
input[data-switch="danger"]:checked + label {
  background-color: var(--bs-red);
}
input[data-switch="light"]:checked + label {
  background-color: var(--bs-gray-300);
}
input[data-switch="dark"]:checked + label {
  background-color: var(--bs-gray-900);
}
.table-centered td,
.table-centered th {
  vertical-align: middle !important;
}
.table .table-user img {
  height: 30px;
  width: 30px;
}
.table .action-icon {
  color: var(--bs-gray-600);
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;
}
.table .action-icon:hover {
  color: var(--bs-gray-700);
}
.table-nowrap td,
.table-nowrap th {
  white-space: nowrap;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-weight: 700;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-22 {
  font-size: 22px !important;
}
.font-24 {
  font-size: 24px !important;
}
.bg-primary-lighten {
  background-color: rgba(83, 109, 230, 0.25) !important;
}
.bg-secondary-lighten {
  background-color: rgba(206, 212, 218, 0.25) !important;
}
.bg-success-lighten {
  background-color: rgba(16, 196, 105, 0.25) !important;
}
.bg-info-lighten {
  background-color: rgba(53, 184, 224, 0.25) !important;
}
.bg-warning-lighten {
  background-color: rgba(249, 200, 81, 0.25) !important;
}
.bg-danger-lighten {
  background-color: rgba(255, 91, 91, 0.25) !important;
}
.bg-light-lighten {
  background-color: rgba(70, 79, 91, 0.25) !important;
}
.bg-dark-lighten {
  background-color: rgba(241, 241, 241, 0.25) !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}
.progress-w-percent .progress {
  width: calc(100% - 50px);
  float: left;
  margin-top: 8px;
}
.progress-w-percent .progress-value {
  width: 40px;
  float: right;
  text-align: right;
  line-height: 20px;
}
.widget-flat {
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .widget-flat i.widget-icon {
    display: none;
  }
}
.widget-icon {
  color: var(--bs-indigo);
  font-size: 20px;
  background-color: rgba(83, 109, 230, 0.25);
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  display: inline-block;
}
.dash-item-overlay {
  position: absolute;
  text-align: left;
  left: 8%;
  max-width: 350px;
  padding: 20px;
  z-index: 1;
}
.chart-content-bg {
  background-color: #444e5a;
}
.chart-content-border {
  border: 1px solid var(--bs-gray-200);
}
.chart-widget-list p {
  border-bottom: 1px solid var(--bs-gray-100);
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}
.timeline-alt {
  padding: 20px 0;
  position: relative;
}
.timeline-alt .timeline-item {
  position: relative;
}
.timeline-alt .timeline-item:before {
  background-color: var(--bs-gray-100);
  bottom: 0;
  content: "";
  left: 9px;
  position: absolute;
  top: 20px;
  width: 2px;
  z-index: 0;
}
.timeline-alt .timeline-item .timeline-icon {
  float: left;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid transparent;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background-color: #fff;
}
.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}
.inbox-widget .inbox-item {
  border-bottom: 1px solid #4b5662;
  overflow: hidden;
  padding: 0.625rem 0;
  position: relative;
}
.inbox-widget .inbox-item:last-of-type {
  border-bottom: none;
}
.inbox-widget .inbox-item .inbox-item-img {
  display: block;
  float: left;
  margin-right: 15px;
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-img img {
  width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
  color: var(--bs-gray-800);
  display: block;
  margin-bottom: 3px;
}
.inbox-widget .inbox-item .inbox-item-text {
  color: var(--bs-gray-500);
  display: block;
  font-size: 0.8125rem;
  margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
  color: var(--bs-gray-600);
  font-size: 0.6875rem;
  position: absolute;
  right: 5px;
  top: 10px;
}
.tilebox-one i {
  position: absolute;
  right: 1.5rem;
  font-size: 2rem;
  opacity: 0.3;
}
.toll-free-box i {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 4rem;
  opacity: 0.4;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}
.cta-box {
  /* background-image: url(../images/bg-pattern.png); */
  background-size: cover;
}
.cta-box .cta-box-title {
  font-size: 20px;
  line-height: 30px;
}
.conversation-list {
  list-style: none;
  padding: 0 15px;
}
.conversation-list li {
  margin-bottom: 20px;
}
.conversation-list li .conversation-actions {
  float: right;
  display: none;
}
.conversation-list li:hover .conversation-actions {
  display: block;
}
.conversation-list .chat-avatar {
  float: left;
  text-align: center;
  width: 42px;
}
.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}
.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}
.conversation-list .ctext-wrap {
  background: var(--bs-gray-100);
  border-radius: 3px;
  display: inline-block;
  padding: 12px;
  position: relative;
}
.conversation-list .ctext-wrap i {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  position: relative;
}
.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
  left: -11px;
  top: 0;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: var(--bs-gray-100);
  border-width: 6px;
  margin-right: -1px;
  border-right-color: var(--bs-gray-100);
}
.conversation-list .conversation-text {
  float: left;
  font-size: 13px;
  margin-left: 12px;
  width: 70%;
}
.conversation-list .odd .chat-avatar {
  float: right !important;
}
.conversation-list .odd .conversation-text {
  float: right !important;
  margin-right: 12px;
  text-align: right;
  width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
  background-color: var(--bs-gray-100);
}
.conversation-list .odd .ctext-wrap:after {
  border-color: transparent;
  border-left-color: var(--bs-gray-100);
  border-top-color: var(--bs-gray-100);
  right: -10px;
  left: auto;
}
.conversation-list .odd .conversation-actions {
  float: left;
}
.calendar-widget .datepicker-inline,
.calendar-widget table {
  width: 100%;
}
.calendar-widget .datepicker-inline tr td,
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget .datepicker-inline tr th,
.calendar-widget table tr td,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day,
.calendar-widget table tr th {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
  color: var(--bs-red) !important;
  text-shadow: none;
  font-weight: 700;
}
.calendar-widget .datepicker-inline tr td.active.day:hover,
.calendar-widget .datepicker-inline tr td.today.day:hover,
.calendar-widget table tr td.active.day:hover,
.calendar-widget table tr td.today.day:hover {
  background-color: transparent !important;
}
.calendar-widget .datepicker-inline td,
.calendar-widget .datepicker-inline th,
.calendar-widget table td,
.calendar-widget table th {
  height: 43px;
}
.calendar-widget .datepicker-inline .datepicker-switch,
.calendar-widget .datepicker-inline .next,
.calendar-widget .datepicker-inline .prev,
.calendar-widget table .datepicker-switch,
.calendar-widget table .next,
.calendar-widget table .prev {
  font-size: 1.1rem;
  background-color: rgba(83, 109, 230, 0.1) !important;
  border-radius: 0;
  color: var(--bs-indigo);
}
.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 2px solid var(--bs-gray-500);
  border-radius: 50%;
  color: var(--bs-gray-500);
}
.social-list-item:hover {
  color: var(--bs-gray-600);
  border-color: var(--bs-gray-600);
}
.horizontal-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.horizontal-steps:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2em;
  background-color: var(--bs-gray-200);
}
.horizontal-steps .process-line {
  display: block;
  position: absolute;
  width: 50%;
  height: 0.2em;
  background-color: var(--bs-indigo);
}
.horizontal-steps .horizontal-steps-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.horizontal-steps .horizontal-steps-content .step-item {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  height: 8px;
  width: 8px;
  margin: 0 2em;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  color: var(--bs-indigo);
  background-color: currentColor;
  border: 0.25em solid var(--bs-body-bg);
  border-radius: 50%;
  z-index: 5;
}
.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}
.horizontal-steps .horizontal-steps-content .step-item:last-child {
  margin-right: 0;
  color: var(--bs-green);
}
.horizontal-steps .horizontal-steps-content .step-item span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
  color: var(--bs-gray-500);
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  display: block;
  position: absolute;
  top: 47.5%;
  left: 51%;
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  -webkit-animation-name: animation-steps-current;
  animation-name: animation-steps-current;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
  color: var(--bs-indigo);
}
@-webkit-keyframes animation-steps-current {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
@keyframes animation-steps-current {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
@media (max-width: 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit;
  }
}
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bs-gray-100);
  z-index: 9999;
}
#status {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -40px 0 0 -40px;
}
@-webkit-keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
    transform: translate3d(0, -16px, 0);
  }
}
@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
    transform: translate3d(0, -16px, 0);
  }
}
.bouncing-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.bouncing-loader > div {
  width: 13px;
  height: 13px;
  margin: 32px 3px;
  background: var(--bs-indigo);
  border-radius: 50%;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
  animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background: var(--bs-red);
}
.bouncing-loader > div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  background: var(--bs-green);
}
.hero-section {
  position: relative;
  padding: 80px 0 120px 0;
}
.hero-section:after {
  content: " ";
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#6379c3),
    to(#546ee5)
  );
  background-image: linear-gradient(to bottom, #6379c3, #546ee5);
  position: absolute;
  top: -400px;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  border-radius: 0;
  -webkit-transform: skewY(-3deg);
  transform: skewY(-3deg);
}
.hero-section .hero-title {
  line-height: 42px;
}
body.authentication-bg {
  /* background-image: url(../images/bg-pattern-dark.png); */
  background-size: cover;
  background-position: center;
}
body.authentication-bg .account-pages {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}
.auth-fluid-pages.enlarged,
.authentication-bg.enlarged,
body.auth-fluid-pages[data-leftbar-compact-mode="condensed"] {
  min-height: 100px;
}
.logout-icon {
  width: 140px;
}
.auth-fluid {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  /* background: url(../images/bg-auth.jpg) center; */
  background-size: cover;
}
.auth-fluid .auth-fluid-form-box {
  max-width: 480px;
  border-radius: 0;
  z-index: 2;
  padding: 3rem 2rem;
  background-color: var(--bs-gray-100);
  position: relative;
  width: 100%;
}
.auth-fluid .auth-fluid-left,
.auth-fluid .auth-fluid-right {
  padding: 6rem 3rem;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
.auth-brand {
  margin-bottom: 2rem;
}
.auth-brand .logo-dark {
  display: none;
}
.auth-brand .logo-light {
  display: block;
}
.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}
.auth-user-testimonial p.lead {
  font-size: 1.125rem;
  margin: 0 auto 20px auto;
  max-width: 700px;
}
@media (min-width: 992px) {
  .auth-brand {
    position: absolute;
    top: 3rem;
  }
}
@media (max-width: 991.98px) {
  .auth-fluid {
    display: block;
  }
  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }
  .auth-fluid .auth-fluid-right {
    display: none;
  }
}
.button-list {
  margin-left: -8px;
  margin-bottom: -12px;
}
.button-list .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 0.5rem;
  overflow: auto;
}
.grid-structure .grid-container {
  background-color: var(--bs-gray-100);
  margin-bottom: 10px;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 10px 20px;
}
.icons-list-demo div {
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}
.icons-list-demo div p {
  margin-bottom: 0;
  line-height: inherit;
}
.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 12px;
  border-radius: 3px;
  display: inline-block;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.icons-list-demo .col-md-4 {
  border-radius: 3px;
  -moz-border-radius: 3px;
  background-clip: padding-box;
  margin-bottom: 10px;
}
.icons-list-demo .col-md-4:hover,
.icons-list-demo .col-md-4:hover i {
  color: var(--bs-indigo);
}
.text-error {
  color: var(--bs-indigo);
  text-shadow: rgba(83, 109, 230, 0.3) 5px 1px, rgba(83, 109, 230, 0.2) 10px 3px;
  font-size: 5.25rem;
  line-height: 5.625rem;
}
.faq-question-q-box {
  height: 30px;
  width: 30px;
  color: var(--bs-indigo);
  background-color: rgba(83, 109, 230, 0.25);
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 50%;
  float: left;
  font-weight: 700;
  line-height: 30px;
}
.faq-question {
  margin-top: 0;
  margin-left: 50px;
  font-weight: 600;
  font-size: 16px;
  color: var(--bs-gray-900);
}
.faq-answer {
  margin-left: 50px;
}
.maintenance-icon {
  font-size: 22px;
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  height: 60px;
  display: inline-block;
  width: 60px;
  line-height: 58px;
  border-radius: 50%;
}
.board {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}
.tasks {
  display: inline-block;
  width: 22rem;
  padding: 0 1rem 1rem 1rem;
  border: 1px solid var(--bs-gray-200);
  vertical-align: top;
  margin-bottom: 24px;
  border-radius: 0.25rem;
}
.tasks.tasks:not(:last-child) {
  margin-right: 1.25rem;
}
.tasks .card {
  white-space: normal;
  margin-top: 1rem;
}
.tasks .task-header {
  background-color: var(--bs-gray-100);
  padding: 1rem;
  margin: 0 -1rem;
}
.task-list-items {
  min-height: 100px;
  position: relative;
}
.task-list-items:before {
  content: "No Tasks";
  position: absolute;
  line-height: 110px;
  width: 100%;
  text-align: center;
  font-weight: 600;
}
.task-modal-content .form-control-light {
  background-color: #3c4651 !important;
  border-color: #3c4651 !important;
}
.gantt-task-details {
  min-width: 220px;
}
.page-aside-left {
  width: 240px;
  float: left;
  padding: 0 20px 20px 10px;
  position: relative;
}
.page-aside-left:before {
  content: "";
  background-color: var(--bs-body-bg);
  width: 5px;
  position: absolute;
  right: -15px;
  height: 100%;
  bottom: -1.5rem;
}
.page-aside-right {
  margin: -1.5rem 0 -1.5rem 250px;
  border-left: 5px solid var(--bs-body-bg);
  padding: 1.5rem 0 1.5rem 25px;
}
.email-list {
  display: block;
  padding-left: 0;
  overflow: hidden;
}
.email-list > li {
  position: relative;
  display: block;
  height: 51px;
  line-height: 50px;
  cursor: default;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.email-list > li a {
  color: var(--bs-gray-700);
}
.email-list > li a:hover {
  color: var(--bs-gray-800);
}
.email-list > li .col-mail {
  float: left;
  position: relative;
}
.email-list > li .email-sender-info {
  width: 320px;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail,
.email-list > li .email-sender-info .star-toggle {
  display: block;
  float: left;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail {
  margin: 15px 10px 0 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.email-list > li .email-sender-info .star-toggle {
  color: var(--bs-gray-500);
  margin-left: 10px;
  font-size: 18px;
}
.email-list > li .email-sender-info .email-title {
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 50px;
}
.email-list > li .email-content {
  position: absolute;
  top: 0;
  left: 320px;
  right: 0;
  bottom: 0;
}
.email-list > li .email-content .email-date,
.email-list > li .email-content .email-subject {
  position: absolute;
  top: 0;
}
.email-list > li .email-content .email-subject {
  left: 0;
  right: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.email-list > li .email-content .email-date {
  right: 0;
  width: 100px;
  text-align: right;
  padding-left: 10px;
}
.email-list > li.active,
.email-list > li.mail-selected {
  background: var(--bs-gray-100);
  -webkit-transition-duration: 0.05s;
  transition-duration: 0.05s;
}
.email-list > li.unread a {
  font-weight: 600;
  color: #e4e4e4;
}
.email-list > li .email-action-icons {
  opacity: 0;
}
.email-list > li .email-action-icons ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: -180px;
}
.email-list > li .email-action-icons ul li {
  margin: 0 10px;
}
.email-list > li .email-action-icons ul .email-action-icons-item {
  font-size: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.email-list > li .email-action-icons ul .email-action-icons-item:hover {
  color: var(--bs-red);
}
.email-list > li:hover {
  background: var(--bs-gray-100);
  -webkit-transition-duration: 0.05s;
  transition-duration: 0.05s;
}
.email-list > li:hover .email-action-icons {
  opacity: 1;
}
.email-list > li:hover .email-action-icons ul {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: 10px;
}
.email-list > li:hover .email-content .email-date {
  opacity: 0;
}
.email-list > li:hover .email-content .email-subject {
  right: 180px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.email-menu-list a {
  color: var(--bs-gray-700);
  padding: 12px 5px;
  display: block;
  font-size: 15px;
}
.email-menu-list a:hover {
  color: var(--bs-gray-800);
}
.email-menu-list a .badge {
  margin-top: 3px;
}
.labels-list a {
  padding: 7px 5px;
}
.write-mdg-box .CodeMirror {
  height: 150px;
}
@media (max-width: 648px) {
  .page-aside-left {
    width: 100%;
    float: none;
    padding: 0 10px 20px 10px;
  }
  .page-aside-left:before {
    width: 0;
  }
  .page-aside-right {
    margin-left: 0;
    border: 0;
    padding-left: 0;
  }
  .email-list li .email-sender-info .checkbox-wrapper-mail {
    margin-left: 0;
  }
}
@media (max-width: 520px) {
  .page-aside-right > .btn-group {
    margin-bottom: 10px;
  }
  .email-list li .email-sender-info {
    width: 150px;
  }
  .email-list li .email-sender-info .email-title {
    left: 80px;
  }
  .email-list li .email-content {
    display: none;
  }
}
.timeline {
  margin-bottom: 50px;
  position: relative;
}
.timeline:before {
  background-color: var(--bs-gray-300);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  top: 30px;
  width: 2px;
  z-index: 0;
}
.timeline-show {
  position: relative;
}
.timeline-show .time-show-name {
  display: inline-block;
  border-radius: 4px;
  background-color: var(--bs-gray-200);
  padding: 7px 15px;
}
.timeline-box {
  background-color: var(--bs-gray-200);
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  display: block;
  margin: 15px 0;
  position: relative;
  padding: 1.5rem;
  border-radius: 0.25rem;
}
.timeline-album {
  margin-top: 12px;
}
.timeline-album a {
  display: inline-block;
  margin-right: 5px;
}
.timeline-album img {
  height: 36px;
  width: auto;
  border-radius: 3px;
}
@media (min-width: 768px) {
  .timeline .timeline-box {
    margin-left: 45px;
  }
  .timeline .timeline-icon {
    background: var(--bs-gray-300);
    border-radius: 50%;
    display: block;
    height: 24px;
    left: -56px;
    margin-top: -12px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 24px;
  }
  .timeline .timeline-icon i {
    color: var(--bs-gray-600);
    font-size: 1rem;
    vertical-align: middle;
  }
  .timeline .timeline-desk {
    display: table-cell;
    vertical-align: top;
    width: 50%;
  }
  .timeline-lg-item {
    display: table-row;
  }
  .timeline-lg-item:before {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item .timeline-desk .arrow {
    border-bottom: 12px solid transparent;
    border-right: 12px solid var(--bs-gray-200) !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: -12px;
    margin-top: -12px;
    position: absolute;
    top: 50%;
    width: 0;
  }
  .timeline-lg-item.timeline-item-left:after {
    content: "";
    display: block;
    width: 50%;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
    border-bottom: 12px solid transparent;
    border-left: 12px solid var(--bs-gray-200) !important;
    border-top: 12px solid transparent;
    display: block;
    height: 0;
    left: auto;
    margin-top: -12px;
    position: absolute;
    right: -12px;
    top: 50%;
    width: 0;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album {
    float: right;
    margin-top: 20px;
  }
  .timeline-lg-item.timeline-item-left .timeline-desk .album a {
    float: right;
    margin-left: 5px;
  }
  .timeline-lg-item.timeline-item-left .timeline-icon {
    left: auto;
    right: -58px;
  }
  .timeline-lg-item.timeline-item-left:before {
    display: none;
  }
  .timeline-lg-item.timeline-item-left .timeline-box {
    margin-right: 45px;
    margin-left: 0;
  }
}
@media (max-width: 767.98px) {
  .timeline .timeline-icon {
    display: none;
  }
}
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}
.daterangepicker:after,
.daterangepicker:before {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}
.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.daterangepicker.opensleft:before {
  right: 9px;
}
.daterangepicker.opensleft:after {
  right: 10px;
}
.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.opensright:before {
  left: 9px;
}
.daterangepicker.opensright:after {
  left: 10px;
}
.daterangepicker.drop-up {
  margin-top: -7px;
}
.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}
.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}
.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}
.daterangepicker.single .drp-selected {
  display: none;
}
.daterangepicker.show-calendar .drp-calendar {
  display: block;
}
.daterangepicker.show-calendar .drp-buttons {
  display: block;
}
.daterangepicker.auto-apply .drp-buttons {
  display: none;
}
.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}
.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}
.daterangepicker .drp-calendar.right {
  padding: 8px;
}
.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid #000;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}
.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}
.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}
.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}
.daterangepicker th.month {
  width: auto;
}
.daterangepicker option.disabled,
.daterangepicker td.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}
.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}
.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
}
.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}
.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}
.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}
.daterangepicker .ranges li:hover {
  background-color: #eee;
}
.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 140px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .daterangepicker.single .drp-calendar,
  .daterangepicker.single .ranges {
    float: left;
  }
  .daterangepicker {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }
  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .daterangepicker .drp-calendar,
  .daterangepicker .ranges {
    float: left;
  }
}
@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}
.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
  min-height: 10px !important;
}
.calendar {
  float: left;
  margin-bottom: 0;
}
.fc-view {
  margin-top: 30px;
}
.none-border .modal-footer {
  border-top: none;
}
.fc-toolbar {
  margin: 6px 0 5px 0 !important;
}
.fc-toolbar .h2,
.fc-toolbar h2 {
  font-size: 1.25rem !important;
  line-height: 1.875rem;
  text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
  font-weight: 600;
}
th.fc-day-header {
  padding: 0.5rem 0;
}
.fc-day {
  background: 0 0;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
}
.fc th.fc-widget-header {
  background: var(--bs-gray-300);
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
  font-weight: 600;
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: var(--bs-gray-300);
}
.fc-unthemed .fc-divider,
.fc-unthemed td.fc-today {
  background: var(--bs-gray-300);
}
.fc-button {
  background: var(--bs-gray-300);
  border: none;
  color: var(--bs-gray-700);
  text-transform: capitalize;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}
.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}
.fc-cell-overlay,
.fc-state-highlight,
.fc-state-hover {
  background: var(--bs-gray-300);
}
.fc-state-active,
.fc-state-disabled,
.fc-state-down {
  background-color: var(--bs-indigo);
  color: #fff;
  text-shadow: none;
}
.fc-unthemed .fc-today {
  background: #fff;
}
.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
  color: #fff;
}
.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #fff;
  border-radius: 4px;
}
.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}
.fc-basic-view .fc-content {
  color: #fff;
}
.fc-time-grid-event .fc-content {
  color: #fff;
}
.fc-daygrid-day-number {
  float: right;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  background-color: var(--bs-gray-100);
  border-radius: 50%;
  margin: 5px;
  font-size: 11px;
  padding: 0 !important;
}
.fc-daygrid-event-dot {
  border-color: #fff;
}
.fc-event-time,
.fc-event-title {
  color: #fff;
}
.fc .fc-list-sticky .fc-list-day > *,
.table-active,
.table-active > td,
.table-active > th {
  background-color: transparent;
}
.fc .fc-list-event:hover td {
  background-color: inherit;
}
@media (max-width: 767.98px) {
  .fc-toolbar {
    display: block;
  }
  .fc-toolbar .fc-center,
  .fc-toolbar .fc-left,
  .fc-toolbar .fc-right {
    float: none;
    display: block;
    clear: both;
    margin: 10px 0;
  }
  .fc .fc-toolbar > * > * {
    float: none;
  }
  .fc-today-button {
    display: none;
  }
}
.fc-toolbar .btn {
  padding: 0.28rem 0.8rem;
  font-size: 0.875rem;
  border-radius: 0.15rem;
}
.fc-list-item-time,
.fc-list-item-title {
  color: #fff;
}
#calendar .table-bordered td,
#calendar .table-bordered th {
  border: 1px solid var(--bs-gray-300);
}
[dir="rtl"] .fc-toolbar-chunk .btn-group .btn:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-radius: 0.15rem;
}
[dir="rtl"] .fc-toolbar-chunk .btn-group .btn:last-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 0.15rem;
}
.chartjs-chart {
  margin: auto;
  position: relative;
  width: 100%;
}
.dataTables_wrapper.container-fluid,
.dataTables_wrapper.container-lg,
.dataTables_wrapper.container-md,
.dataTables_wrapper.container-sm,
.dataTables_wrapper.container-xl,
.dataTables_wrapper.container-xxl {
  padding: 0;
}
table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 0.5rem;
  left: auto;
  content: "\F0360";
  font-family: "Material Design Icons";
  font-size: 1rem;
  top: 12px;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  left: auto;
  content: "\F035D";
  font-family: "Material Design Icons";
  top: 18px;
  font-size: 1rem;
}
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc {
  padding-right: 30px;
  padding-left: 0.95rem;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background-color: var(--bs-indigo);
}
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected td {
  border-color: var(--bs-indigo);
}
table.dataTable tbody td:focus {
  outline: 0 !important;
}
table.dataTable tbody td.focus,
table.dataTable tbody th.focus {
  outline: 2px solid var(--bs-indigo) !important;
  outline-offset: -1px;
  background-color: rgba(83, 109, 230, 0.15);
}
.dataTables_info {
  font-weight: 600;
}
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr[role="row"]
  > th:first-child:before {
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--bs-green);
  top: auto;
  bottom: auto;
}
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > td:first-child:before,
table.dataTable.dtr-inline.collapsed
  > tbody
  > tr.parent
  > th:first-child:before {
  background-color: var(--bs-red);
}
div.dt-button-info {
  background-color: var(--bs-indigo);
  border: none;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}
div.dt-button-info .h2,
div.dt-button-info h2 {
  border-bottom: none;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
@media (max-width: 767.98px) {
  li.paginate_button.next,
  li.paginate_button.previous {
    display: inline-block;
    font-size: 1.5rem;
  }
  li.paginate_button {
    display: none;
  }
  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important;
  }
  div.dt-buttons {
    display: inline-table;
    margin-bottom: 1.5rem;
  }
}
.activate-select .sorting_1 {
  background-color: var(--bs-gray-100);
}
div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}
@media (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_filter {
    text-align: center;
  }
}
div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  margin-right: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-left: 0;
}
.daterangepicker {
  font-family: Nunito, sans-serif;
  border: 1px solid #444d58;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  background-color: var(--bs-gray-300);
}
.daterangepicker .calendar-table {
  border: 1px solid var(--bs-gray-300);
  background-color: var(--bs-gray-300);
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
  color: var(--bs-gray-600);
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: var(--bs-gray-500);
}
.daterangepicker .ranges li:hover {
  background-color: #4d5764;
}
.daterangepicker .ranges li.active {
  background-color: var(--bs-indigo);
}
.daterangepicker td.in-range {
  background-color: #515c69;
  color: var(--bs-gray-700);
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: var(--bs-gray-300);
  color: var(--bs-gray-500);
  opacity: 0.5;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--bs-indigo);
  color: #fff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: var(--bs-indigo);
  color: #fff;
}
.daterangepicker:after {
  border-bottom: 6px solid var(--bs-gray-300);
}
.daterangepicker:before {
  border-bottom: 7px solid #444d58;
}
.daterangepicker .drp-buttons {
  border-top: 1px solid #444d58;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  background: #515c69;
  border: 1px solid #515c69;
  color: var(--bs-gray-500);
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 1px solid #444d58;
}
.datepicker {
  padding: 10px !important;
}
.datepicker td,
.datepicker th {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 13px;
  line-height: 28px;
}
.datepicker table tr td .active.disabled:hover[disabled],
.datepicker table tr td .active.disabled[disabled],
.datepicker table tr td .active:active,
.datepicker table tr td .active:hover,
.datepicker table tr td .active:hover.active,
.datepicker table tr td .active:hover.disabled,
.datepicker table tr td .active:hover:active,
.datepicker table tr td .active:hover:hover,
.datepicker table tr td .active:hover[disabled],
.datepicker table tr td .active[disabled],
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled],
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
  background-color: var(--bs-indigo) !important;
  background-image: none !important;
  color: #fff;
}
.datepicker table tr td span.focused,
.datepicker table tr td span:hover,
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
  background: var(--bs-gray-200);
}
.datepicker table tr td span.new,
.datepicker table tr td span.old,
.datepicker table tr td.new,
.datepicker table tr td.old {
  color: var(--bs-gray-700);
  opacity: 0.4;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: var(--bs-gray-200);
}
.datepicker .datepicker-switch:hover {
  background: 0 0;
}
.datepicker-dropdown:after {
  border-bottom: 6px solid var(--bs-gray-300);
}
.datepicker-dropdown:before {
  border-bottom-color: #444d58;
}
.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid #444d58;
}
.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid var(--bs-gray-300);
}
[dir="rtl"] .daterangepicker.opensright:after {
  right: 10px;
  left: auto;
}
[dir="rtl"] .daterangepicker.opensright:before {
  right: 9px;
  left: auto;
}
.bg-dragula {
  background-color: var(--bs-gray-100);
}
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
}
.dragula-handle {
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 24px;
  text-align: center;
  cursor: move;
}
.dragula-handle:before {
  content: "\F01DB";
  font-family: "Material Design Icons";
  position: absolute;
}
.dropzone {
  border: 2px dashed #4a525d;
  background: var(--bs-gray-100);
  border-radius: 6px;
  cursor: pointer;
  min-height: 150px;
  padding: 20px;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2rem 0;
}
.dropzone.dz-started .dz-message {
  display: none;
}
.form-wizard-header {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: var(--bs-gray-100);
}
.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: var(--bs-gray-100);
  border-radius: 3px;
}
.jvectormap-label {
  border: none;
  background: var(--bs-gray-800);
  color: var(--bs-gray-100);
  font-family: Nunito, sans-serif;
  font-size: 0.9rem;
  padding: 5px 8px;
}
.ql-editor {
  text-align: left;
}
.ql-container {
  font-family: Nunito, sans-serif;
}
.ql-container.ql-snow {
  border-color: #4a525d;
}
.ql-bubble {
  border: 1px solid #4a525d;
  border-radius: 0.25rem;
}
.ql-toolbar {
  font-family: Nunito, sans-serif !important;
}
.ql-toolbar span {
  outline: 0 !important;
  color: var(--bs-gray-500);
}
.ql-toolbar span:hover {
  color: var(--bs-indigo) !important;
}
.ql-toolbar.ql-snow {
  border-color: #4a525d;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent;
}
.ql-snow .ql-script,
.ql-snow .ql-strike svg,
.ql-snow .ql-stroke {
  stroke: var(--bs-gray-500);
}
.ql-snow .ql-fill {
  fill: var(--bs-gray-500);
}
.ql-snow .ql-picker-options {
  background-color: var(--bs-gray-300);
  border-color: #444d58 !important;
}
.select2-container {
  width: 100% !important;
}
.select2-container .select2-selection--single {
  border: 1px solid #4a525d;
  height: calc(1.5em + 0.9rem + 2px);
  background-color: var(--bs-gray-100);
  outline: 0;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
  padding-left: 12px;
  color: var(--bs-gray-800);
}
.select2-container .select2-selection--single .select2-selection__arrow {
  height: 34px;
  width: 34px;
  right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: var(--bs-gray-600) transparent transparent transparent;
  border-width: 6px 6px 0 6px;
}
.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent var(--bs-gray-600) transparent !important;
  border-width: 0 6px 6px 6px !important;
}
.select2-results__option {
  padding: 6px 12px;
}
.select2-dropdown {
  border: 1px solid #444d58;
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  background-color: var(--bs-gray-300);
}
.select2-container--default .select2-search--dropdown {
  padding: 10px;
  background-color: #4c5562;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: 0;
  border: 1px solid #4a525d;
  background-color: var(--bs-gray-100);
  color: var(--bs-gray-800);
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: var(--bs-indigo);
}
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #4c5562;
  color: #fff;
}
.select2-container--default
  .select2-results__option[aria-selected="true"]:hover {
  background-color: var(--bs-indigo);
  color: #fff;
}
.select2-container .select2-selection--multiple {
  min-height: calc(1.5em + 0.9rem + 2px);
  border: 1px solid #4a525d !important;
  background-color: var(--bs-gray-100);
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 10px;
}
.select2-container .select2-selection--multiple .select2-search__field {
  border: 0;
  color: var(--bs-gray-800);
}
.select2-container .select2-selection--multiple .select2-selection__choice {
  background-color: var(--bs-indigo);
  border: none;
  color: #fff;
  border-radius: 3px;
  padding: 0 7px;
  margin-top: 6px;
}
.select2-container
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #fff;
  margin-right: 5px;
}
.select2-container
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #fff;
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 7px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  right: 1px;
  left: auto;
}
[dir="rtl"] .select2-container--open .select2-dropdown {
  left: auto;
  right: 0;
}
[data-simplebar] {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.simplebar-content:after,
.simplebar-content:before {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}
.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 5px;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #a2adb7;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  -webkit-transition: opacity 0s linear;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}
.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
}
.custom-scroll {
  height: 100%;
}
[data-simplebar-lg] .simplebar-scrollbar {
  right: 1px;
  width: 10px;
}
[data-simplebar-primary] .simplebar-scrollbar:before {
  background: var(--bs-indigo);
}
.jq-toast-single {
  text-align: left !important;
  padding: 15px;
  font-family: Nunito, sans-serif;
  background-color: var(--bs-indigo);
  font-size: 13px;
  line-height: 22px;
}
.jq-toast-single .h2,
.jq-toast-single h2 {
  font-family: Nunito, sans-serif;
}
.jq-toast-single a {
  font-size: 0.9rem;
}
.jq-toast-single a:hover {
  color: #fff;
}
.jq-has-icon {
  padding: 10px;
}
.close-jq-toast-single {
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 20px;
  cursor: pointer;
  height: 32px;
  width: 32px;
  background: var(--bs-gray-800);
  color: var(--bs-gray-100);
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}
.jq-toast-loader {
  height: 3px;
  top: 0;
  border-radius: 0;
}
.jq-icon-primary {
  background-color: var(--bs-indigo);
  color: #fff;
  border-color: var(--bs-indigo);
}
.jq-icon-secondary {
  background-color: var(--bs-gray-600);
  color: #fff;
  border-color: var(--bs-gray-600);
}
.jq-icon-success {
  background-color: var(--bs-green);
  color: #fff;
  border-color: var(--bs-green);
}
.jq-icon-info {
  background-color: var(--bs-cyan);
  color: #fff;
  border-color: var(--bs-cyan);
}
.jq-icon-warning {
  background-color: var(--bs-yellow);
  color: #fff;
  border-color: var(--bs-yellow);
}
.jq-icon-danger {
  background-color: var(--bs-red);
  color: #fff;
  border-color: var(--bs-red);
}
.jq-icon-light {
  background-color: var(--bs-gray-300);
  color: #fff;
  border-color: var(--bs-gray-300);
}
.jq-icon-dark {
  background-color: var(--bs-gray-900);
  color: #fff;
  border-color: var(--bs-gray-900);
}
.jq-icon-error {
  background-color: var(--bs-red);
  color: #fff;
  border-color: var(--bs-red);
}
.jq-icon-error,
.jq-icon-info,
.jq-icon-success,
.jq-icon-warning {
  background-image: none;
}
.bootstrap-touchspin .btn .input-group-text {
  padding: 0;
  border: none;
  background-color: transparent;
  color: inherit;
}
.bootstrap-timepicker-widget table td input {
  height: 32px;
  width: 32px;
  color: #fff;
  background-color: var(--bs-indigo);
  border-radius: 50%;
  border: 0;
  outline: 0 !important;
}
.bootstrap-timepicker-widget table td a {
  color: var(--bs-gray-700);
}
.bootstrap-timepicker-widget table td a:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--bs-indigo);
}
.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid #444d58;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid var(--bs-gray-300);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid var(--bs-gray-300);
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #444d58;
}
.CodeMirror-fullscreen,
.editor-toolbar.fullscreen {
  z-index: 1000;
}
.editor-preview,
.editor-preview-side {
  background: var(--bs-gray-300);
}
.editor-preview-active {
  background: #4c5562;
}
.editor-toolbar {
  border: 1px solid #4a525d;
  border-bottom: 0;
}
.editor-toolbar a {
  color: var(--bs-gray-700) !important;
}
.editor-toolbar a.active,
.editor-toolbar a:hover {
  background-color: transparent;
  color: var(--bs-indigo) !important;
  border-color: transparent;
}
.editor-toolbar i.separator {
  display: none;
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: 0 0;
}
.CodeMirror {
  border: 1px solid #4a525d;
  background: var(--bs-gray-100);
  color: var(--bs-gray-800);
  min-height: 100px;
}
.CodeMirror-cursor {
  border-left: 1px solid var(--bs-gray-800);
}
.editor-statusbar {
  color: var(--bs-gray-800);
}
.twitter-typeahead {
  display: inherit !important;
}
.tt-hint,
.tt-query {
  outline: 0;
}
.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
  color: var(--bs-gray-800);
  background: var(--bs-gray-100) !important;
}
.tt-menu {
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: var(--bs-gray-300);
  -webkit-box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  box-shadow: 0 0 35px 0 rgba(49, 57, 66, 0.5);
  border: 1px solid #444d58;
}
.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}
.tt-suggestion.tt-cursor,
.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: var(--bs-indigo);
}
.tt-suggestion p {
  margin: 0;
}
.tt-highlight {
  font-family: Nunito, sans-serif;
}
.typeahead-empty-message {
  padding: 5px 10px;
  color: var(--bs-red);
}
.league-name {
  padding: 3px 20px;
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: auto !important;
  height: auto !important;
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 10px !important;
  border-radius: 3px;
  border-color: #fff !important;
}
.jqsfield {
  color: #000 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  font-family: Nunito, sans-serif !important;
  font-weight: 700 !important;
}
.timepicker-orient-bottom {
  top: auto !important;
  bottom: calc(1.5em + 0.9rem + 2px) !important;
}
.bootstrap-timepicker-widget {
  left: 0 !important;
  right: auto !important;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
  right: auto;
}
.bootstrap-timepicker-widget.timepicker-orient-left::after {
  left: 7px;
  right: auto;
}
.rateit {
  display: -moz-inline-box;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.rateit .rateit-range * {
  display: block;
}
.rateit .rateit-hover,
.rateit .rateit-selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}
.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  left: auto;
  right: 0;
}
.rateit .rateit-hover {
  color: var(--bs-yellow);
}
.rateit .rateit-hover-rtl {
  background-position: right -32px;
}
.rateit .rateit-selected {
  color: var(--bs-red);
}
.rateit .rateit-selected-rtl {
  background-position: right -16px;
}
.rateit .rateit-preset {
  color: var(--bs-orange);
}
.rateit button.rateit-reset {
  width: 16px;
  height: 16px;
  float: left;
  outline: 0;
  border: none;
  padding: 0;
}
.rateit .rateit-reset span {
  display: none;
}
.rateit .rateit-range {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  height: 16px;
  outline: 0;
}
.rateit.rateit-font .rateit-reset {
  width: 0.6em;
  height: 0.6em;
  margin-right: 5px;
  margin-top: 5px;
  background: var(--bs-gray-300);
  border-radius: 50%;
  position: relative;
}
.rateit.rateit-font .rateit-reset span {
  display: block;
  height: calc(50% - 0.045em);
  top: 2px;
  position: absolute;
  border-bottom: 2px solid var(--bs-gray-700);
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
.rateit.rateit-font .rateit-reset:hover,
.rateit.rateit-font button.rateit-reset:focus {
  background: var(--bs-red);
}
.rateit.rateit-font .rateit-reset:hover span,
.rateit.rateit-font button.rateit-reset:focus span {
  border-color: #fff;
}
.rateit-mdi {
  font-family: "Material Design Icons";
}
.rateit-font {
  font-size: 24px;
  line-height: 1em;
}
.rateit-font .rateit-range {
  background: 0 0;
  height: auto;
}
.rateit-font .rateit-range > div {
  background: 0 0;
  overflow: hidden;
  cursor: default;
  white-space: nowrap;
}
.rateit-font .rateit-empty {
  color: var(--bs-gray-400);
}
.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  right: auto;
}
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 12px;
}
.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: 0 !important;
}
.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}
.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}
.irs-handle {
  position: absolute;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
}
.irs-handle.type_last {
  z-index: 2;
}
.irs-max,
.irs-min {
  position: absolute;
  display: block;
  cursor: default;
}
.irs-min {
  left: 0;
  right: auto;
}
.irs-max {
  right: 0;
  left: auto;
}
[dir="rtl"] .irs-min {
  right: 0;
  left: auto;
}
[dir="rtl"] .irs-max {
  left: 0;
  right: auto;
}
.irs-from,
.irs-single,
.irs-to {
  position: absolute;
  display: block;
  top: 0;
  cursor: default;
  white-space: nowrap;
}
.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}
.irs-with-grid .irs-grid {
  display: block;
}
.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: var(--bs-gray-600);
}
.irs-grid-pol.small {
  height: 4px;
}
.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: var(--bs-gray-600);
}
.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}
.lt-ie9 .irs-disable-mask {
  background: var(--bs-gray-600);
  cursor: not-allowed;
}
.irs-disabled {
  opacity: 0.4;
}
.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: 0 !important;
  z-index: -9999 !important;
  background: 0 0 !important;
  border-style: solid !important;
  border-color: transparent !important;
}
.irs--flat {
  height: 40px;
}
.irs--flat.irs-with-grid {
  height: 60px;
}
.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: var(--bs-gray-300);
  border-radius: 4px;
}
.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: var(--bs-indigo);
}
.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}
.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: var(--bs-gray-300);
}
.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent;
}
.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #3453e1;
}
.irs--flat .irs-max,
.irs--flat .irs-min {
  top: 0;
  padding: 1px 3px;
  color: var(--bs-gray-700);
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: var(--bs-gray-300);
  border-radius: 4px;
}
.irs--flat .irs-from,
.irs--flat .irs-single,
.irs--flat .irs-to {
  color: #fff;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: var(--bs-indigo);
  border-radius: 4px;
}
.irs--flat .irs-from:before,
.irs--flat .irs-single:before,
.irs--flat .irs-to:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: var(--bs-indigo);
}
.irs--flat .irs-grid-pol {
  background-color: var(--bs-gray-300);
}
.irs--flat .irs-grid-text {
  color: var(--bs-gray-700);
}
[dir="rtl"] .irs-bar--single {
  border-radius: 0 4px 4px 0 !important;
}
.jstree-default {
  padding: 2px 6px;
  height: auto;
}
.jstree-default .jstree-clicked,
.jstree-default .jstree-hovered {
  background: var(--bs-gray-300);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.jstree-default .jstree-anchor,
.jstree-default .jstree-icon,
.jstree-default .jstree-icon:empty {
  line-height: 20px;
}
.jstree-wholerow.jstree-wholerow-clicked,
.jstree-wholerow.jstree-wholerow-hovered {
  background: var(--bs-gray-300);
}
.gantt .bar-progress {
  fill: var(--bs-indigo);
}
.gantt .bar-wrapper:hover .bar-progress {
  fill: #6980e9;
}
.gantt .bar,
.gantt .bar-wrapper:hover .bar {
  fill: var(--bs-gray-300);
}
.gantt .bar-label,
.gantt .bar-label.big {
  fill: var(--bs-gray-700);
}
.gantt .grid-header {
  fill: var(--bs-gray-100);
  stroke: var(--bs-gray-100);
}
.gantt .grid-row {
  fill: transparent;
}
.gantt .grid-row:nth-child(even) {
  fill: var(--bs-gray-100);
}
.gantt .lower-text,
.gantt .upper-text {
  fill: var(--bs-gray-500);
}
.gantt .row-line {
  stroke: var(--bs-gray-300);
}
.gantt .today-highlight {
  fill: var(--bs-gray-300);
}
.gantt-container .popup-wrapper .pointer {
  display: none;
}
/*# sourceMappingURL=app-modern-dark.min.css.map */
