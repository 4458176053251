:root,
.rs-theme-dark {
  --rs-gray-50: #e9ebf0;
  --rs-gray-100: #cbced4;
  --rs-gray-200: #a4a9b3;
  --rs-gray-300: #858b94;
  --rs-gray-400: #6a6f76;
  --rs-gray-500: #5c6066;
  --rs-gray-600: #3c3f43;
  --rs-gray-700: #292d33;
  --rs-gray-800: #1a1d24;
  --rs-gray-900: #0f131a;
  --rs-primary-50: #f2fcff;
  --rs-primary-100: #ccf3ff;
  --rs-primary-200: #a6e9ff;
  --rs-primary-300: #80ddff;
  --rs-primary-400: #59d0ff;
  --rs-primary-500: #34c3ff;
  --rs-primary-600: #25b3f5;
  --rs-primary-700: #169de0;
  --rs-primary-800: #0a81c2;
  --rs-primary-900: #006199;
  --rs-red-50: #ffeded;
  --rs-red-100: #fccaca;
  --rs-red-200: #faa9a7;
  --rs-red-300: #f58884;
  --rs-red-400: #f26a63;
  --rs-red-500: #f04f43;
  --rs-red-600: #e63f30;
  --rs-red-700: #d12f1d;
  --rs-red-800: #b3200c;
  --rs-red-900: #8a1200;
  --rs-orange-50: #fff9f2;
  --rs-orange-100: #ffe2c2;
  --rs-orange-200: #ffce91;
  --rs-orange-300: #ffba61;
  --rs-orange-400: #ffa930;
  --rs-orange-500: #ff9800;
  --rs-orange-600: #f59700;
  --rs-orange-700: #e08e00;
  --rs-orange-800: #c27e00;
  --rs-orange-900: #996600;

  --rs-yellow-50: #fff9f0;
  --rs-yellow-100: #ffedd1;
  --rs-yellow-200: #ffe2b3;
  --rs-yellow-300: #ffd894;
  --rs-yellow-400: #ffcf75;
  --rs-yellow-500: #ffc757;
  --rs-yellow-600: #f5bb3d;
  --rs-yellow-700: #e0a824;
  --rs-yellow-800: #c28f0e;
  --rs-yellow-900: #997000;
  --rs-green-50: #f3fff2;
  --rs-green-100: #cff0ce;
  --rs-green-200: #aee0ad;
  --rs-green-300: #8ccf8c;
  --rs-green-400: #71bf72;
  --rs-green-500: #58b15b;
  --rs-green-600: #3fab45;
  --rs-green-700: #27a12f;
  --rs-green-800: #10911b;
  --rs-green-900: #007d0c;
  --rs-cyan-50: #f2ffff;
  --rs-cyan-100: #bcf4f7;
  --rs-cyan-200: #87e6ed;
  --rs-cyan-300: #57dae6;
  --rs-cyan-400: #2acadb;
  --rs-cyan-500: #00bcd4;
  --rs-cyan-600: #00b1cc;
  --rs-cyan-700: #00a0bd;
  --rs-cyan-800: #008aa6;
  --rs-cyan-900: #006e87;
  --rs-blue-50: #edf9ff;
  --rs-blue-100: #c0e8fc;
  --rs-blue-200: #93d6fa;
  --rs-blue-300: #67c1f5;
  --rs-blue-400: #3dadf2;
  --rs-blue-500: #1499ef;
  --rs-blue-600: #0e8ce6;
  --rs-blue-700: #087ad1;
  --rs-blue-800: #0464b3;
  --rs-blue-900: #00498a;
  --rs-violet-50: #f6f2ff;
  --rs-violet-100: #d5c9f0;
  --rs-violet-200: #b6a1e3;
  --rs-violet-300: #987bd4;
  --rs-violet-400: #805ac7;
  --rs-violet-500: #673ab7;
  --rs-violet-600: #5f2bb3;
  --rs-violet-700: #531ba8;
  --rs-violet-800: #470c99;
  --rs-violet-900: #390085;
  --rs-state-success: #4caf50;
  --rs-state-info: #2196f3;
  --rs-state-warning: #ffb300;
  --rs-state-error: #f44336;
  --rs-state-success-glass: #4caf5040;
  --rs-state-info-glass: #2196f340;
  --rs-state-warning-glass: #ffb30040;
  --rs-state-error-glass: #f4433640;
  --rs-body: #0f131a;
  --rs-text-link: #34c3ff;
  --rs-text-link-hover: #59d0ff;
  --rs-text-link-active: #80ddff;
  --rs-text-primary: #e9ebf0;
  --rs-text-secondary: #a4a9b3;
  --rs-text-tertiary: #858b94;
  --rs-text-heading: #fff;
  --rs-text-inverse: #1a1d24;
  --rs-text-heading-inverse: #0f131a;
  --rs-text-active: #34c3ff;
  --rs-text-disabled: #5c6066;
  --rs-border-primary: var(--dark-gray);
  --rs-border-secondary: #292d33;
  --rs-bg-card: #1a1d24;
  --rs-bg-overlay: var(--bg-dark-lighter);
  --rs-bg-well: #0f131a;
  --rs-bg-active: var(--a-purple);
  --rs-bg-backdrop: rgba(32, 32, 32, 0.8);
  --rs-state-hover-bg: #3c3f43;
  --rs-color-focus-ring: 0 0 0 3px #FFCC3300;
  --rs-state-focus-shadow: 0 0 0 3px #FFCC3300;
  --rs-state-focus-outline: 3px solid #FFCC3300;
  --rs-shadow-overlay: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-btn-default-bg: #3c3f43;
  --rs-btn-default-text: #e9ebf0;
  --rs-btn-default-hover-bg: #5c6066;
  --rs-btn-default-active-bg: #858b94;
  --rs-btn-default-active-text: #fff;
  --rs-btn-default-disabled-bg: #3c3f43;
  --rs-btn-default-disabled-text: #5c6066;
  --rs-btn-primary-bg: var(--a-purple);
  --rs-btn-primary-text: #fff;
  --rs-btn-primary-hover-bg: var(--a-purple--hover);
  --rs-btn-primary-active-bg: #59d0ff;
  --rs-btn-subtle-text: #a4a9b3;
  --rs-btn-subtle-hover-bg: var(--bg-dark);
  --rs-btn-subtle-hover-text: #e9ebf0;
  --rs-btn-subtle-active-bg: #6a6f76;
  --rs-btn-subtle-active-text: #fff;
  --rs-btn-subtle-disabled-text: #5c6066;
  --rs-btn-ghost-border: #34c3ff;
  --rs-btn-ghost-text: #34c3ff;
  --rs-btn-ghost-hover-border: #59d0ff;
  --rs-btn-ghost-hover-text: #59d0ff;
  --rs-btn-ghost-active-border: #a6e9ff;
  --rs-btn-ghost-active-text: #a6e9ff;
  --rs-btn-link-text: var(--a-purple);
  --rs-btn-link-hover-text: var(--a-purple--hover);
  --rs-btn-link-active-text: #a6e9ff;
  --rs-iconbtn-addon: #5c6066;
  --rs-iconbtn-activated-addon: #6a6f76;
  --rs-iconbtn-pressed-addon: #a4a9b3;
  --rs-iconbtn-primary-addon: #25b3f5;
  --rs-iconbtn-primary-activated-addon: #34c3ff;
  --rs-iconbtn-primary-pressed-addon: #59d0ff;
  --rs-divider-border: #3c3f43;
  --rs-loader-ring: rgba(233, 235, 240, 0.3);
  --rs-loader-rotor: #fff;
  --rs-loader-backdrop: rgba(15, 19, 26, 0.83);
  --rs-loader-ring-inverse: rgba(233, 235, 240, 0.8);
  --rs-loader-rotor-inverse: #5c6066;
  --rs-loader-backdrop-inverse: rgba(255, 255, 255, 0.9);
  --rs-message-success-header: #fff;
  --rs-message-success-text: #fff;
  --rs-message-success-icon: #fff;
  --rs-message-success-bg: #58b15b;
  --rs-message-info-header: #fff;
  --rs-message-info-text: #fff;
  --rs-message-info-icon: #fff;
  --rs-message-info-bg: #1499ef;
  --rs-message-warning-header: #0f131a;
  --rs-message-warning-text: #0f131a;
  --rs-message-warning-icon: #0f131a;
  --rs-message-warning-bg: #ffc757;
  --rs-message-error-header: #fff;
  --rs-message-error-text: #fff;
  --rs-message-error-icon: #fff;
  --rs-message-error-bg: #f04f43;
  --rs-tooltip-bg: #5c6066;
  --rs-tooltip-text: #fff;
  --rs-progress-bg: #292d33;
  --rs-progress-bar: #34c3ff;
  --rs-progress-bar-success: #4caf50;
  --rs-progress-bar-fail: #f44336;
  --rs-placeholder: #3c3f43;
  --rs-placeholder-active: #484c50;
  --rs-dropdown-divider: #3c3f43;
  --rs-dropdown-item-bg-hover: #3c3f43;
  --rs-dropdown-item-bg-active: rgba(0, 97, 153, 0.2);
  --rs-dropdown-item-text-active: #34c3ff;
  --rs-dropdown-header-text: #5c6066;
  --rs-dropdown-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2), 0 4px 4px 3px rgba(0, 0, 0, 0.24);
  --rs-menuitem-active-bg: #3c3f43;
  --rs-menuitem-active-text: currentColor;
  --rs-steps-border: #a4a9b3;
  --rs-steps-state-finish: #34c3ff;
  --rs-steps-border-state-finish: #34c3ff;
  --rs-steps-state-wait: #a4a9b3;
  --rs-steps-state-process: #169de0;
  --rs-steps-state-error: #f44336;
  --rs-steps-border-state-error: #f44336;
  --rs-steps-icon-state-process: #34c3ff;
  --rs-steps-icon-state-error: #f44336;
  --rs-navs-text: #a4a9b3;
  --rs-navs-text-hover: #cbced4;
  --rs-navs-bg-hover: var(--bg-dark);
  --rs-navs-text-active: #fff;
  --rs-navs-bg-active: #6a6f76;
  --rs-navs-tab-border: #3c3f43;
  --rs-navs-subtle-border: #3c3f43;
  --rs-navs-selected: #34c3ff;
  --rs-navbar-default-bg: var(--bg-dark);
  --rs-navbar-default-text: #a4a9b3;
  --rs-navbar-default-selected-text: #34c3ff;
  --rs-navbar-default-hover-bg: var(--dark-gray);
  --rs-navbar-default-hover-text: #e9ebf0;
  --rs-navbar-inverse-bg: #169de0;
  --rs-navbar-inverse-text: #fff;
  --rs-navbar-inverse-selected-bg: #59d0ff;
  --rs-navbar-inverse-hover-bg: #25b3f5;
  --rs-navbar-inverse-hover-text: #fff;
  --rs-navbar-subtle-bg: transparent;
  --rs-navbar-subtle-text: #a4a9b3;
  --rs-navbar-subtle-selected-text: #34c3ff;
  --rs-navbar-subtle-hover-bg: #292d33;
  --rs-navbar-subtle-hover-text: #e9ebf0;
  --rs-sidenav-default-bg: var(--bg-dark);
  --rs-sidenav-default-text: #a4a9b3;
  --rs-sidenav-default-selected-text: var(--a-purple);
  --rs-sidenav-default-hover-bg: var(--dark-gray);
  --rs-sidenav-default-hover-text: var(--a-purple);
  --rs-sidenav-inverse-bg: #169de0;
  --rs-sidenav-inverse-text: #fff;
  --rs-sidenav-inverse-selected-bg: #59d0ff;
  --rs-sidenav-inverse-hover-bg: #25b3f5;
  --rs-sidenav-subtle-bg: transparent;
  --rs-sidenav-subtle-text: #a4a9b3;
  --rs-sidenav-subtle-selected-text: #34c3ff;
  --rs-sidenav-subtle-hover-bg: #292d33;
  --rs-sidenav-subtle-hover-text: #e9ebf0;
  --rs-input-bg: var(--dark-gray);
  --rs-input-focus-border: var(--dark-gray);
  --rs-input-disabled-bg: var(--dark-gray-1);
  --rs-listbox-option-hover-bg: var(--dark-gray-1);
  --rs-listbox-option-hover-text: currentColor;
  --rs-listbox-option-selected-text: var(--a-purple);
  --rs-listbox-option-selected-bg: #ff223322;
  --rs-listbox-option-disabled-text: #5c6066;
  --rs-listbox-option-disabled-selected-text: #a6e9ff;
  --rs-checkbox-icon: #1a1d24;
  --rs-checkbox-border: #6a6f76;
  --rs-checkbox-checked-bg: var(--a-purple);
  --rs-checkbox-disabled-bg: #5c6066;
  --rs-radio-marker: #1a1d24;
  --rs-radio-border: #6a6f76;
  --rs-radio-checked-bg: #FFCC33;
  --rs-radio-disabled-bg: #5c6066;
  --rs-rate-symbol: #3c3f43;
  --rs-rate-symbol-checked: #ffc757;
  --rs-toggle-bg: #6a6f76;
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: #858b94;
  --rs-toggle-disabled-bg: #3c3f43;
  --rs-toggle-disabled-thumb: #5c6066;
  --rs-toggle-checked-bg: #169de0;
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #25b3f5;
  --rs-toggle-checked-disabled-bg: #006199;
  --rs-toggle-checked-disabled-thumb: #858b94;
  --rs-slider-bar: #3c3f43;
  --rs-slider-hover-bar: #3c3f43;
  --rs-slider-thumb-border: #34c3ff;
  --rs-slider-thumb-bg: #292d33;
  --rs-slider-thumb-hover-shadow: 0 0 0 8px rgba(52, 195, 255, 0.25);
  --rs-slider-progress: #34c3ff;
  --rs-uploader-item-bg: #858b94;
  --rs-uploader-item-hover-bg: #3c3f43;
  --rs-uploader-overlay-bg: rgba(60, 63, 67, 0.8);
  --rs-uploader-dnd-bg: var(--dark-gray);
  --rs-uploader-dnd-border: #a4a9b3;
  --rs-uploader-dnd-hover-border: var(--a-purple);
  --rs-avatar-bg: #6a6f76;
  --rs-avatar-text: #fff;
  --rs-badge-bg: #f44336;
  --rs-badge-text: #fff;
  --rs-tag-bg: #3c3f43;
  --rs-tag-close: #f44336;
  --rs-carousel-bg: #3c3f43;
  --rs-carousel-indicator: rgba(255, 255, 255, 0.4);
  --rs-carousel-indicator-hover: #fff;
  --rs-carousel-indicator-active: #34c3ff;
  --rs-panel-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-list-bg: #0f131a;
  --rs-list-border: #292d33;
  --rs-list-hover-bg: #3c3f43;
  --rs-list-placeholder-bg: rgba(0, 97, 153, 0.2);
  --rs-list-placeholder-border: #34c3ff;
  --rs-timeline-indicator-bg: #5c6066;
  --rs-timeline-indicator-active-bg: #5c6066;
  --rs-table-shadow: rgba(9, 9, 9, 0.99);
  --rs-table-sort: var(--a-purple);
  --rs-table-resize: var(--a-purple);
  --rs-table-scrollbar-track: #292d33;
  --rs-table-scrollbar-thumb: #a4a9b3;
  --rs-table-scrollbar-thumb-active: #cbced4;
  --rs-table-scrollbar-vertical-track: #292d33;
  --rs-drawer-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-modal-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  --rs-form-errormessage-text: #fff;
  --rs-form-errormessage-bg: #f44336;
  --rs-form-errormessage-border: #f44336;
  --rs-picker-value: var(--text-color);
  --rs-picker-count-bg: var(--a-purple);
  --rs-picker-count-text: var(--invert-theme-text-color);
  --rs-calendar-today-bg: #169de0;
  --rs-calendar-today-text: #fff;
  --rs-calendar-range-bg: #FFCC3340;
  --rs-calendar-time-unit-bg: #3c3f43;
  --rs-calendar-date-selected-text: #fff;
  --rs-popover-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}


.radio-red{
  --rs-radio-checked-bg: var(--rs-state-error);
  --rs-color-focus-ring: 0 0 0 3px #f4433640;
  --rs-state-focus-shadow: 0 0 0 3px #f4433640;
  --rs-state-focus-outline: 3px solid #f4433640;
}
.radio-yellow{
  --rs-radio-checked-bg: var(--rs-state-warning);
  --rs-color-focus-ring: 0 0 0 3px #ffb30040;
  --rs-state-focus-shadow: 0 0 0 3px #ffb30040;
  --rs-state-focus-outline: 3px solid #ffb30040;
}
.radio-blue{
  --rs-radio-checked-bg: var(--rs-blue-700);
  --rs-color-focus-ring: 0 0 0 3px #087ad140;
  --rs-state-focus-shadow: 0 0 0 3px #087ad140;
  --rs-state-focus-outline: 3px solid #087ad140;
}
.radio-orange{
  --rs-radio-checked-bg: var(--rs-orange-600);
  --rs-color-focus-ring: 0 0 0 3px #f5970040;
  --rs-state-focus-shadow: 0 0 0 3px #f5970040;
  --rs-state-focus-outline: 3px solid #f5970040;
}
.radio-green{
  --rs-radio-checked-bg: var(--rs-state-success);
  --rs-color-focus-ring: 0 0 0 3px #4caf5040;
  --rs-state-focus-shadow: 0 0 0 3px #4caf5040;
  --rs-state-focus-outline: 3px solid #4caf5040;
}
.radio-white{
  --rs-radio-checked-bg: #fff;
  --rs-color-focus-ring: 0 0 0 3px #ffffff40;
  --rs-state-focus-shadow: 0 0 0 3px #ffffff40;
  --rs-state-focus-outline: 3px solid #ffffff40;
}

.rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  left: 10px;
  right: unset;
}
.rs-picker-menu{
  max-height:300px
}

a{
  transition:color 0.2s ease;
}
a:focus, a:hover{
  color:var(--a-purple--light);
  transition:color 0.2s ease;
  text-decoration: none;
}

.badge-tlp{
  color:#030303
}

.badge-tlp-red{
  background-color:var(--rs-state-error-glass);
  color:var(--rs-state-error);
}
.badge-tlp-amber{
  background-color:var(--rs-state-warning-glass);
  color:var(--rs-state-warning);
}
.badge-tlp-green{
  background-color:#10c4692e;
  color:#10c469;
}
.badge-tlp-white{
  background-color:#ffffff40;
  color:#fff;
}
.badge-priority-vl{
  background: #ffee002e;;
  color:#ffee00;
}
.badge-priority-l{
  background-color:var(--rs-state-warning-glass);
  color:var(--rs-state-warning);
}
.badge-priority-m{
  background-color:#ff7b002e;
  color:#ff7b00;
}
.badge-priority-h{
  background-color:#ff51002e;
  color:#ff5100;
}
.badge-priority-vh{
  background-color:var(--rs-state-error-glass);
  color:var(--rs-state-error);
}

.rs-form-vertical .rs-form-group .rs-form-control-wrapper{
  width:100%;
}

.rs-table-scrollbar{
  background:var(--dark-gray)!important;
}
.rs-table-scrollbar-horizontal .rs-table-scrollbar-handle{
  background:var(--rs-gray-400)!important;
}

@media (max-width:768px) {
  .rs-modal-full{
    width:95%;
  }
 
  body[data-layout="detached"] .content-page{
    padding:0
  }
}
.content-page {
  min-height: calc(100vh - 80px);
}
/* .rs-navbar-item, .rs-navbar-nav>.rs-dropdown .rs-dropdown-toggle, */
/* .rs-navbar-nav.rs-navbar-right, */
.rs-navbar-brand, .rs-navbar-header{
  height:100%
}

.rs-sidenav-toggle .rs-btn{
  width:100%;
  /* height:56px; */
  font-size: 20px;
}
.rs-sidenav-toggle .rs-btn svg{
  width:60%;
  height:60%
}

.rs-nav-default .rs-nav-item:focus, 
.rs-nav-default .rs-nav-item:hover, 
.rs-nav-tabs .rs-nav-item:focus,
.rs-nav-tabs .rs-nav-item:hover{
  background: var(--rs-navs-bg-hover);
}

.in-out-toggle {
  --rs-toggle-bg: #10c469;
  --rs-toggle-thumb: #fff;
  --rs-toggle-hover-bg: #23d47c;
  --rs-toggle-disabled-bg: #3c3f43;
  --rs-toggle-disabled-thumb: #5c6066;

  --rs-toggle-checked-bg: var(--rs-state-error);
  --rs-toggle-checked-thumb: #fff;
  --rs-toggle-checked-hover-bg: #f55145;
  /* --rs-toggle-checked-disabled-bg: #006199;
  --rs-toggle-checked-disabled-thumb: #858b94; */
}
.rs-toggle-lg .rs-toggle-inner{
  line-height: 2;
}

.authed .rs-picker-toggle:hover,
.authed .rs-picker-toggle:focus,
.authed .rs-picker-toggle{
  border-color:var(--rs-state-success)!important;
}
.unauthed .rs-picker-toggle:hover,
.unauthed .rs-picker-toggle:focus,
.unauthed .rs-picker-toggle{
  border-color:var(--rs-state-error)!important;
}