body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.font-shabnam{
  font-family: "shabnam";
}
.dir-rtl{
  direction:rtl
}
.like-pre{
  white-space:pre-wrap
}

.text-justify{
  text-align: justify;
}

.rs-picker-menu{
  text-align: right!important;
}

.rs-picker-toggle-value,
.rs-picker-toggle .rs-picker-toggle-placeholder{
  text-align: right!important;
  display: block;
}

